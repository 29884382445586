import { z } from 'zod';
import { s } from 'i18n';
import { Attachment } from 'graphql-api';
import checkAttachmentSize from './checkAttachmentSize';

export const messageRequiredError = 'Message can\'t be empty';
export const answerRequiredError = 'Answer can\'t be empty';

export const zNotRequired = z.string().nullish();
export const zText = (errorText?: string) => (errorText
  ? z.string({ message: errorText })
    .trim()
    .min(1, errorText)
  : z.string()
    .trim()
    .min(1));

export const zMessageText = (errorText?: string) => zText(errorText || messageRequiredError);

export const zMessageAnswer = (errorText?: string) => zText(errorText || answerRequiredError);

export const zMessageKeywords = (errorText?: string) => z.array(zText(errorText)).nonempty(errorText);

export const zMessageAttachment = (
  attachmentList: Attachment[],
  maxAttachmentSize?: number,
) => z.nullable(z
  .custom(
    () => checkAttachmentSize(maxAttachmentSize),
    s('Attachments are not allowed on your route'),
  )
  .and(
    z.custom(
      (attId) => {
        const attach = attachmentList?.find((att) => att.id === attId);
        return !!attach?.size;
      },
      s('The file is broken and cannot be attached to the message'),
    ),
  )
  .and(
    z.custom(
      (attId) => {
        const attach = attachmentList?.find((att) => att.id === attId);
        return !!attach?.size && checkAttachmentSize(maxAttachmentSize, attach.size);
      },
      s('The file is too big'),
    ),
  ),
).optional();
