import { s } from 'i18n';
import {
  Box, Stack, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  CampaignStatus,
  ConversationTemplate,
} from 'src/graphql-api';
import { getSdk as templateGetSdk } from 'products/Templates/query.generated';
import { fetcher } from 'graphql-api/fetcher';
import {
 DataGrid, ApiQueryParams, ApiQueryResult, useGridRef,
} from '@xeebi/neru';
import Imports from 'products/common/CampaignShared/Imports';
import { ImportType } from 'products/common/types';
import { hasPermissionById, PERMISSION_GENERAL } from 'permission';
import { CampaignDetailsProps } from 'products/Details';
import TemplateType from 'products/Templates/types';
import Struct, { DRIP, REGULAR, ARCHIVE } from './Struct';
import { GridType, CampaignStatus as CS } from './types';
import { getCampaignStatuses } from './helpers';
import { getSdk as campaignGetSdk } from './query.generated';


export const apiCampaign = campaignGetSdk(fetcher);
const apiTemplate = templateGetSdk(fetcher);

export const loadCampaigns = (tab: string, gridType: GridType) => async (params: ApiQueryParams) => {
  const gridFilter = params.filter === 'null'
    ? ''
    : `, ${params.filter}`;

  const typeFilter = tab === DRIP
    ? ', {"type": {"$in": [6]}}'
    : ', {"type": {"$in": [0, 1, 2, 3, 4]}}';

  const statusFilter = tab === ARCHIVE
    ? ', {"status": 6}'
    : ', {"status":{"$ne": 6}}';

  const chatFilter = gridType === GridType.Campaign
    ? '{"isChat": false}'
    : '{"isChat": true}';

  params.filter = `{"$and":[${chatFilter}${typeFilter}${gridFilter}${statusFilter}]}`;

  const { campaignCount, campaign } = await apiCampaign.getVoteSmsCampaigns(params);
  return { count: campaignCount, rows: campaign } as ApiQueryResult;
};

export default function Grid({
    gridType,
}: GetGridProps) {
  const navigate = useNavigate();
  const gridRef = useGridRef();
  const [templates, setTemplates] = useState<ConversationTemplate[]>([]);
  const [tab, setTab] = useState(REGULAR);

  const campaignStatuses = useMemo(
    () => getCampaignStatuses(gridType).filter((status) => status.id !== CS.Archived) as CampaignStatus[],
    [gridType]);

  const hasActionPermission = hasPermissionById(PERMISSION_GENERAL);
  const path = gridType === GridType.Campaign ? '/campaign' : '/poll';

  const loadData = useMemo(() => loadCampaigns(tab, gridType), [tab, gridType]);

  useEffect(() => {
    const loadStatuses = async () => {
      try {
        const { conversationTemplate } = await apiTemplate.getConversationTemplate({
          filter: gridType === GridType.Campaign
            ? `{"type": ${TemplateType.Text}}`
            : gridType === GridType.Poll
              ? `{"type": ${TemplateType.Poll}}`
              : `{"type": ${TemplateType.OptIn}}`,
          sort: '[{"name": 1}]',
        });
        setTemplates(conversationTemplate);
      } catch (e) {
        console.log(e);
      }
    };
    loadStatuses();
  }, [gridType]);

  const columns = useMemo(() => {
    return Struct({
      campaignStatuses, templates, gridRef, gridType, hasActionPermission, tab,
    });
  }, [campaignStatuses, templates, gridRef, gridType, hasActionPermission, tab]);

  useEffect(() => {
    gridRef.current.refresh();
  }, [gridRef, loadData]);

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Imports type={ImportType.Campaign} title={s('Creating campaigns')} />
      </Box>

      <ToggleButtonGroup
        value={tab}
        onChange={(e, v) => v && setTab(v)}
        exclusive
        aria-label="charts-interval"
      >
        <ToggleButton key={REGULAR} value={REGULAR}>{REGULAR}</ToggleButton>
        <ToggleButton key={DRIP} value={DRIP}>{DRIP}</ToggleButton>
        <ToggleButton key={ARCHIVE} value={ARCHIVE}>{ARCHIVE}</ToggleButton>
      </ToggleButtonGroup>

      <DataGrid
        primaryKey="id"
        storageId={'CampaignGrid' + gridType + 'Storage'}
        refreshTimeout={15_000}
        pageSize={12}
        apiRef={gridRef}
        getRows={loadData}
        columns={columns}
        onRowClick={(row) => {
          if (row.row?.status?.id === CS.Draft) {
            navigate(`${path}/edit/${row.id}`);
          } else {
            navigate(`${path}/${row.id}`);
          }
        }}
      />

      <Outlet
        context={{
          gridRef,
          type: gridType,
        } as CampaignDetailsProps}
      />
    </Stack>
  );
}

type GetGridProps = {
  gridType: GridType
};
