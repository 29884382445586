import React, { useCallback, useState } from 'react';
import { s } from 'i18n';
import { Icon, Button } from '@mui/material';
import Loading from 'shared-scope/components/Loading';
import styles from './loading.module.scss';


type FileUploaderProps = {
  onChange: (f: File | null) => void
  disabled: boolean
  loading: boolean
  value: File | null
  progress?: number
  className?: string
};

export default function FileUploader({
   onChange,
   value,
   progress,
   disabled = false,
   loading = false,
   className = '',
 }: FileUploaderProps) {
  const [over, setOver] = useState(false);

  const handleChange = useCallback((e: any) => {
    e.preventDefault();

    let file;
    let rest;
    if (e.dataTransfer) { // @ts-ignore
      [file, ...rest] = e.dataTransfer.files;
    } else if (e.target) {
      file = e.target?.files ? e.target.files[0] : null;
    }

    if (window.File && file instanceof File) {
      onChange(file);
    } else {
      onChange(null);
    }
  }, [onChange]);

  const handleDragOver = useCallback((e) => {
    if (disabled) {
      return;
    }
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
    setOver(true);
  }, [setOver, disabled]);

  const handleDragLeave = useCallback(() => {
    if (disabled) {
      return;
    }
    setOver(false);
  }, [setOver, disabled]);

  const handleDrop = useCallback((e) => {
    if (disabled) {
      return;
    }
    setOver(false);
    handleChange(e);
  }, [handleChange, setOver, disabled]);

  return <div
    className={`${styles.fileUploader} ${disabled ? '' : styles.active} ${over ? styles.dragActive : ''} ${className}`}
    onDragLeave={() => handleDragLeave()}
    onDragOver={(e) => handleDragOver(e)}
    onDrop={(e) => handleDrop(e)}
  >
    <label className={`${styles.labelStyle} d-flex align-items-center justify-content-center flex-column p-2`}>
      {loading
        ? <Loading
            size={100}
            progress={progress}
        />
        : (value && value.name)
          ? <>
            <Icon className="icon-file-check" fontSize="large" />
            <h5>{s('":name" ready to upload', { name: value.name })}</h5>
            <Button
              component="label"
              variant="outlined"
              disabled={disabled}
            >
              {s('Replace file...')}
              <input type="file" className="d-none" onChange={handleChange} />
            </Button>
          </>
          : <>
            <Icon className="icon-upload" fontSize="large" />
            <h4 className="text-center">{s('Drag and drop file')}</h4>
            <Button
              component="label"
              variant="contained"
              disabled={disabled}
            >
              {s('Or click here to browse files...')}
              <input type="file" className="d-none" onChange={handleChange} />
            </Button>
          </>}
      <span className="muted mt-auto">{s('.csv, .txt, .xlsx up to 500 Mb')}</span>
    </label>
  </div>;
}
