import {
 Box, Button, Stack, Typography,
} from '@mui/material';
import { s } from 'i18n';
import React, { useEffect, useState } from 'react';
import { ConfirmDlg, useConfirmation } from 'shared-scope/components/ConfirmDlg';
import axios, { AxiosRequestConfig } from 'axios';
import * as CONFIG from 'shared-scope/config';
import { useRequest } from '@xeebi/neru';
import Loading from 'shared-scope/components/Loading';
import useAlert from 'shared-scope/hooks/useAlert';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const getAccount = async (
  data: Record<string, any>,
  config?: AxiosRequestConfig,
) => axios.get(
  `${CONFIG.get('api')}/account`,
  data,
);

const generateApiToken = async (
  data: Record<string, any>,
  config?: AxiosRequestConfig,
) => axios.post(
  `${CONFIG.get('api')}/account/generate-api-key`,
  data,
  config,
);

export default function API() {
  const [showConfirm, hideConfirm, confirmParams] = useConfirmation();
  const { addError } = useAlert();
  const {
   fetch: fetchAccount, result: account, isLoading: accountLoading, error: accError,
  } = useRequest(getAccount);
  const {
    fetch: generateToken, result: generateResult, isLoading: generateLoading, error: generateError,
  } = useRequest(generateApiToken);

  const [apiToken, setApiToken] = useState(s('no API key'));

  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  useEffect(() => {
    setApiToken(generateResult?.data?.api_token || account?.data?.api_token || s('no API key'));
  }, [account, generateResult]);

  useEffect(() => {
    accError && addError(accError.getMessage());
    generateError && addError(generateError.getMessage());
  }, [accError, generateError, addError]);

  const path = CONFIG.get('api');
  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Typography variant="h4">{s('API token')}</Typography>
        <Box sx={{ padding: '1rem', backgroundColor: '#eee', borderRadius: '3px' }}>
          {accountLoading || generateLoading ? <Loading size={24} /> : <Typography variant="body1">{apiToken}</Typography>}
        </Box>
        <Button
          variant="contained"
          size="medium"
          disabled={accountLoading || generateLoading}
          onClick={() => showConfirm(<Box>{s('Are you sure you want to generate new API token?')}</Box>, hideConfirm)}
        >
          {s('Generate new key')}
        </Button>
      </Stack>
      <ConfirmDlg
        title={s('Are you sure?')}
        confirmParams={{
          ...confirmParams,
          onOk: () => {
            hideConfirm();
            generateToken();
          },
        }}
        maxWidth="lg"
      />
      <SwaggerUI url={`${path}/apidoc/messaging`} />
    </Box>
  );
}
