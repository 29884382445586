import React, { useEffect, useMemo, useState } from 'react';
import { s } from 'i18n';
import {
  Box,
  TextField,
  Typography,
  Divider,
  Switch,
  Stack,
} from '@mui/material';
import * as Encoding from 'shared-scope/helpers/encoding';
import { Encod } from 'shared-scope/helpers/encoding';
import { Campaign } from 'graphql-api';
import WhiteBox from 'shared-scope/components/Box/WhiteBox';
import Scheduler from 'shared-scope/components/Scheduler';
import { createAnswer, createQuestion } from 'products/shared/Campaign/helpers';
import { GridType } from 'products/CampaignList/types';
import * as CONFIG from 'shared-scope/config';
import fileCheck from 'media/images/file_check.svg';
import sample from 'lodash/sample';
import { MessageType } from 'products/shared/Campaign/types';
import { Phone, PhonePreview } from 'products/shared/Campaign/components';
import PhoneBook from 'products/common/CampaignShared/components/PhoneBook';


export type GeneralProps = {
  campaign: Campaign | null
  type: GridType
  name: string
  onName: Function
  scheduleTimesMask: string | null
  scheduleTimes: string
  onScheduleTimes: Function
  scheduleEnabled: boolean
  onScheduleEnabled: Function
  tz: string
  onTz: Function
  phoneBookId: number | null
  onPhoneBookId: (v: number | null) => void
  showRoute?: boolean
};

export function RequiredMessage() {
  return <Typography color="error" variant="caption">{s('Name is required')}</Typography>;
}

export function General({
  campaign,
  type,
  name,
  onName,
  scheduleTimesMask,
  scheduleTimes,
  onScheduleTimes,
  scheduleEnabled,
  onScheduleEnabled,
  phoneBookId,
  onPhoneBookId,
  showRoute = false,
}: GeneralProps) {
  const text = campaign?.messageTemplates?.[0]?.text || '';
  const route = campaign?.route?.name || '';
  const segments = Encoding.splitSegments(text, [Encod.gsm7, Encod.ucs2]);
  // eslint-disable-next-line
  const questions = useMemo(() => campaign?.conversationTemplate?.questions || [], [campaign?.id]);
  const [chat, setChat] = useState<MessageType[]>([]);

  useEffect(() => {
    if (type === GridType.OptIn) {
      const newChat: MessageType[] = [];
      const initAnswer = campaign?.params?.reply_actions?.length
        ? sample(campaign.params.reply_actions[0].keywords?.split(' ')) || ''
        : '';
      const doubleVQuestion = questions?.[0]?.question || '';
      const doubleVQuestionAtt = questions?.[0]?.attachment_id || null;
      const doubleVAnswer = questions?.[0]?.keywords?.[0] || '';
      const finalMessage = campaign?.conversationTemplate?.final_message || '';
      const finalAtt = campaign?.conversationTemplate?.final_attachment_id || null;

      initAnswer && newChat.push(createAnswer(initAnswer));
      doubleVQuestion && newChat.push(createQuestion(doubleVQuestion, doubleVQuestionAtt));
      doubleVAnswer && newChat.push(createAnswer(doubleVAnswer));
      finalMessage && newChat.push(createQuestion(finalMessage, finalAtt));
      setChat(newChat);
    }
  }, [questions, type, campaign?.conversationTemplate, campaign?.params?.reply_actions]);

  return (
    <WhiteBox>
      <Stack spacing={2} direction="row" flexWrap="wrap" useFlexGap>
        <Stack direction="column" spacing={4} flexGrow={1}>
          <Typography variant="h3">{s('General')}</Typography>
          <TextField
            label={s('Name')}
            value={name}
            onChange={(e) => onName(e.target.value)}
            variant="outlined"
            error={!name}
            helperText={name ? '' : <RequiredMessage />}
            fullWidth
            required
            sx={{ width: '600px' }}
          />
          {showRoute
            ? (
              <TextField
                label={s('Route')}
                value={route}
                variant="outlined"
                fullWidth
                required
                disabled
                sx={{ width: '600px' }}
              />
            )
            : null}

          <Divider color="grey" />

          {type === GridType.Campaign
            ? (
              <>
                <Typography variant="h3">{s('Your message')}</Typography>
                <Typography>{text}</Typography>
                <Typography color="text.secondary" variant="subtitle1">
                  {`${text.length}/${segments?.per_segment} (${segments?.segments.length} SMS, ${segments?.name})`}
                </Typography>
                {campaign?.attachmentId
                  ? (
                    <Box
                      sx={{
                        height: 100,
                        width: 160,
                      }}
                    >
                      <img
                        src={`${CONFIG.get('api_host')}${PUBLIC_PATH}api/fs/attachment-file/${campaign.attachmentId}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                        alt=""
                        loading="eager"
                        onError={(event) => {
                          event.currentTarget.src = fileCheck;
                        }}
                      />
                    </Box>

                  )
                  : null}
                <Divider color="grey" />
              </>
            )
            : null}

          <Stack direction="row" spacing={4}>
            {type === GridType.OptIn
              ? (
                <PhoneBook phoneBookId={phoneBookId} onChange={onPhoneBookId} />
              )
              : (
                <>
                  <Typography variant="h3">{s('Schedule')}</Typography>
                  <Switch
                    checked={scheduleEnabled}
                    onChange={() => onScheduleEnabled(!scheduleEnabled)}
                    disabled={!!scheduleTimesMask}
                  />
                </>
              )}
          </Stack>

          {scheduleEnabled
            ? (
              <>
                <Typography variant="subtitle1">{s('Timezone of message recipient will be used')}</Typography>
                <Scheduler
                  compact
                  onChange={(v: string) => onScheduleTimes(v)}
                  value={scheduleTimes}
                  mask={scheduleTimesMask}
                  disabled={false}
                />
              </>
            )
            : null}
        </Stack>
        {type === GridType.Poll
          ? (
            <PhonePreview
              initialMessage={text}
              initialAnswer={campaign?.params?.reply_actions?.length
                ? sample(campaign.params.reply_actions[0].keywords?.split(' ')) || null
                : null}
              initialAttachment={campaign?.attachmentId || null}
              finalMessage={campaign?.conversationTemplate?.final_message || ''}
              finalAttachment={campaign?.conversationTemplate?.final_attachment_id || null}
              questions={questions}
            />
          )
          : null}
        {type === GridType.OptIn
          ? (
            <Phone chat={chat} height={400} />
          )
          : null}
      </Stack>
    </WhiteBox>
  );
}
