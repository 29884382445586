import { Campaign } from 'graphql-api';

export type TZ = {
  k: string
  v: string
  offset: number
};

export type LoadingStatus = null | 'loading' | 'waiting';
export type LoadingAction = { [k: number]: LoadingStatus };
export type ConfirmationFunction = (name: string, action: () => void) => void;

/**
 * Campaign statuses
 */
export const CampaignStatus = {
  Stopped: 0,
  Starting: 1,
  Running: 2,
  Scheduled: 3,
  Stopping: 4,
  Complete: 5,
  Archived: 6,
  Suspended: 7,
  Cancelling: 8,
  Cancelled: 9,
  WaitingForApproval: 10,
  Declined: 11,
  ProcessingSchedule: 12,
  Draft: 13,
  Approved: 14,
} as const;
export type CampaignStatuses = typeof CampaignStatus[keyof typeof CampaignStatus];

export interface CampaignETA extends Campaign {
  lastUpdateETA?: number
  eta?: string
  middleSpeedETA?: number
}

export interface Route {
  route_id: number
  route_name: string
}

export type Mapping = {
  address_id?: number
  phone_number?: number
  country?: number
  state?: number
  tz?: number
  first_name?: number
  last_name?: number
  gender?: number
  custom_1?: number
  custom_2?: number
  custom_3?: number
  custom_4?: number
  custom_5?: number
  custom_6?: number
  custom_7?: number
  custom_8?: number
  custom_9?: number
  custom_10?: number
  client_message_id?: number
};

export type MessageTemplate = {
  id?: number
  text: string
  type?: 'generated' | 'default'
  enabled?: boolean
  low_click_rate?: boolean
  low_heartbeat_rate?: boolean
  high_filtering_rate?: boolean
};

export type ShortLink = {
  id?: string
  url_desktop: string
  url_mobile: string
  url_preview?: string
  redirect_limit: number
  expire_ts: string
  placeholder: string
};

export type ShortLinkBook = {
  placeholder: string
  book_id?: number | null
};

// cur_params
export type CampaignParams = {
  shortlink_books: ShortLinkBook[]
  shortlinks: ShortLink[]
  message_payload: boolean
  preview_messages: string[]
  shortlink_expire_ts: string
  shortlink_redirect_limit?: number
  impression_url: string
  shortlink_protocol: boolean
  shortlink_hosts: string[]
  shortlink_book_id: number
  shortlink_track:boolean
  defaults: { [key: string]: string }
  phone_cut: number
  phone_add: string
  skip: number
  data_rowset: string[][]
  geo_clicker_en?: boolean
  boost_mode: boolean
  connection_id: number
  country_id: number
  message_templates: MessageTemplate[]
  sms_heartbeat_template_enabled: boolean
  sms_heartbeat_enabled: boolean
  campaign_source_type: string
  mapping: Mapping
  shortlink_type: string
  shortlink_mobile: string
  shortlink_domains: string[]
  shortlink_hashtags: string[]
  shortlink_use_subdomain: boolean
  shortlink_use_domains_sequentially: boolean
  shortlink_geo_clicker_detection: boolean
  shortlink_preview: string
  shortlink_desktop: string
  transaction_limit_enabled: boolean
  transaction_limit: number
  transaction_limit_interval: number
  third_party_url_shortener?: boolean
  third_party_url_shortener_enabled: boolean
  third_party_url_shortener_link_max_messages: number
};

export enum GridType {
  Campaign,
  Poll,
  AgentPoll,
  OptIn,
}

export interface ImprovedCampaign extends Campaign {
  cSmsErrorCount: number
  smsErrorCount: number
  cErrorCount: number
  errorCount: number
  filteredCount: number
  sentPct: number
  errorPct: number
  deliveredPct: number
  filteredPct: number
  successCount: number
  cSuccessCount: number
  smsSuccessCount: number
  cSmsSuccessCount: number
  ssSuccessCount: number
  ssSmsSuccessCount: number
  ssMessagesTotal: number
  ssSmsTotal: number
  ssErrorCount: number
  ssSmsErrorCount: number
  messagesTotal: number
  cMessagesTotal: number
  smsTotal: number
  cSmsTotal: number
}
