import { getSdk, GetTemplatesQuery } from 'shared-scope/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import { Combobox, useRequest } from '@xeebi/neru';
import React, { useEffect, useMemo, useState } from 'react';
import {
 Box, Stack, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { s } from 'i18n';
import { QuestionsTemplate } from 'products/common/types';
import { FormController, useFormContext } from 'shared-scope/components/Form';
import { Maybe } from 'shared-scope/types';
import { ConversationTemplate } from 'graphql-api';
import { FormRowsErrors } from 'shared-scope/components/Form/types';
import { z } from 'zod';
import { find } from 'lodash';
import useAttachments from 'shared-scope/hooks/useAttachments';
import {
  createQuestion, createAnswer,
  isCorrectOptIn, getQuestionsError,
} from '../helpers';
import { OptInQuestions, Phone } from '../components';
import {
  MessageType, FormField, TemplateType,
} from '../types';

const api = getSdk(fetcher);

const getTemplates = async () => api.getTemplates({
  filter: JSON.stringify({ type: TemplateType.OptIn }),
});

export default function StepOptInMessage({ maxAttachmentSize, templateId }: StepOptInMessageProps) {
  const {
    isLoading,
    error: fetchError,
    fetch: fetchTemplates,
    result: templates,
  } = useRequest<GetTemplatesQuery>(getTemplates);

  const { attachmentList } = useAttachments();

  const {
    form: {
      setValue,
      getError,
      setError,
      validateFields,
    },
    formRow: { template, questions },
  } = useFormContext<StepOptInMessageRow>();

  const [questionType, setQuestionType] = useState(QuestionsType.Template);
  const [chat, setChat] = useState<MessageType[]>([]);

  /**
   * Fetch templates & attachments list
   */
  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  /**
   * Error handle
   */
  useEffect(() => {
    setError(FormField.template, fetchError ? fetchError.getMessage() : '');
  }, [fetchError, setError]);

  /**
   * Set template
   */
  useEffect(() => {
    if (templateId && templates?.conversationTemplate) {
      const t = find(templates.conversationTemplate, { id: templateId });
      t && setValue(FormField.template, t);
      setValue(FormField.questions, t as QuestionsTemplate);
    }
  }, [templates?.conversationTemplate, templateId, setValue]);

  /**
   * Set chat to phone
   */
  useEffect(() => {
    if (questions) {
      setChat(generateOptInChat(questions, getError));
    } else {
      setChat([]);
    }
  }, [questions, getError, attachmentList, maxAttachmentSize]);

  const optInRule = useMemo(
    () => isCorrectOptIn(attachmentList, maxAttachmentSize),
    [attachmentList, maxAttachmentSize],
  );

  /**
   * Revalidate fields on change Route
   */
  useEffect(() => {
    validateFields(
      FormField.questions,
      { [FormField.questions]: isCorrectOptIn(attachmentList, maxAttachmentSize, { message: false, answer: false }) },
    );
  }, [maxAttachmentSize, attachmentList, validateFields]);

  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      useFlexGap
    >
      <Box>
        <ToggleButtonGroup
          value={questionType}
          onChange={(e, v) => {
            setValue(FormField.template, null);
            setValue(FormField.questions, null);
            return v !== null && setQuestionType(v);
          }}
          exclusive
          aria-label="questions-group"
        >
          <ToggleButton value={QuestionsType.Template}>{s('From template')}</ToggleButton>
          <ToggleButton value={QuestionsType.Manual}>{s('Manual')}</ToggleButton>
        </ToggleButtonGroup>
        <Stack spacing={3} sx={{ mt: 3 }}>
          { questionType === QuestionsType.Template
            ? <FormController<Maybe<ConversationTemplate>>
                name={FormField.template}
                validateRule={isSetQuestion}
            >
              {(ctrl) => (
                <Combobox<ConversationTemplate>
                  title={s('Opt-in template')}
                  value={ctrl.value || null}
                  options={templates?.conversationTemplate || []}
                  onChange={(e, v) => {
                    ctrl.onChange(v);
                    setValue(FormField.questions, v as QuestionsTemplate);
                  }}
                  loading={isLoading}
                  error={!!ctrl.error}
                  helperText={ctrl.error}
                  optionKey="id"
                  optionValue="name"
                  sx={{ width: 600 }}
                />
              )}
            </FormController>
            : null }
          {(template || questionType === QuestionsType.Manual)
            ? <FormController<Maybe<QuestionsTemplate>>
                name={FormField.questions}
                validateRule={optInRule}
            >
              {(ctrl) => (
                <OptInQuestions
                  value={ctrl.value || null}
                  onChange={(v, templateChanged) => {
                    ctrl.onChange(v);
                    templateChanged && setValue(FormField.template, null);
                    templateChanged && setQuestionType(QuestionsType.Manual);
                    setValue(FormField.questions, v as QuestionsTemplate);
                  }}
                  error={ctrl.fullError}
                  maxAttachmentSize={maxAttachmentSize}
                />
              )}
            </FormController>
            : null}
        </Stack>
      </Box>
      <Box>
        <Phone chat={chat} height={400} />
      </Box>
    </Stack>
  );
}

export type StepOptInMessageRow = {
  template?: ConversationTemplate
  questions?: Maybe<QuestionsTemplate>
};

type StepOptInMessageProps = {
  maxAttachmentSize?: number
  templateId?: Maybe<number>
};

enum QuestionsType {
  Template,
  Manual,
}

const generateOptInChat = (questions: QuestionsTemplate, getError: (name: string) => FormRowsErrors) => {
  const err = getError(FormField.questions) as FormRowsErrors;
  const attErrors = getQuestionsError(err);

  const newChat: MessageType[] = [];
  const keywords = questions?.initialAnswer?.split(' ');
  const initAnswer = keywords[Math.floor(Math.random() * keywords.length)] || '';
  const doubleVQuestion = questions?.template?.questions?.[0]?.question || '';
  const doubleVQuestionAtt = !attErrors.questions?.[0]?.attachment
    ? questions?.template?.questions?.[0]?.attachment_id || null
    : null;
  const doubleVAnswer = questions?.template?.questions?.[0]?.keywords?.[0] || '';
  const finalMessage = questions?.template?.final_message || '';
  const finalAtt = !attErrors.final?.attachment
    ? questions?.template?.final_attachment_id || null
    : null;

  initAnswer && newChat.push(createAnswer(initAnswer));
  doubleVQuestion && newChat.push(createQuestion(doubleVQuestion, doubleVQuestionAtt));
  doubleVAnswer && newChat.push(createAnswer(doubleVAnswer));
  finalMessage && newChat.push(createQuestion(finalMessage, finalAtt));

  return newChat;
};

const isSetQuestion = (row: StepOptInMessageRow) => z.custom(
  () => !!row.template || !!row.questions,
  s('Select a template or enter questions manually'),
);
