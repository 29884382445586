import {
 Box, IconButton, Stack, Typography, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import * as CONFIG from 'shared-scope/config';
import fileCheck from 'media/images/file_check.svg';

export default function Preview({
                   attachment, onChange, error, fileName,
                 }: PreviewProps) {
  const { palette } = useTheme();
  const [isFile, setIsFile] = useState<boolean>(false);

  return <Stack>
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      sx={{ bgColor: '#FFFF00' }}
    >
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          display: 'inline-block',
        }}
      >
        <Box sx={{ width: '100%', height: '100%', zIndex: 1 }}>
          <img
            src={`${CONFIG.get('api_host')}${PUBLIC_PATH}api/fs/attachment-file/${attachment}`}
            alt=""
            loading="eager"
            height="50px"
            onError={(event) => {
              setIsFile(true);
              event.currentTarget.src = fileCheck;
            }}
          />
        </Box>

        <IconButton
          aria-label="Delete attachment"
          onClick={() => onChange(null)}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 5,
            backgroundColor: palette.primary.main,
            color: palette.layout.light,
            borderRadius: '50%',
            border: '2px solid',
            borderColor: palette.layout.light,
            width: '24px',
            height: '24px',
            '&:hover': {
              color: palette.primary.main,
              backgroundColor: palette.primary.light,
            },
          }}
        >
          <Typography fontSize="20px" marginTop="1px"><i className="icon-close" /></Typography>
        </IconButton>
      </Box>
      <Typography variant="subtitle1" color={palette.error.main}>{error}</Typography>
    </Stack>
    {(isFile && fileName)
      ? <Typography variant="caption">{fileName}</Typography>
      : null}
  </Stack>;
}

type PreviewProps = {
  attachment: string
  fileName?: string
  onChange: (v: string | null) => void
  error?: string
};
