import { s } from 'i18n';
import { FormController, useFormContext } from 'shared-scope/components/Form';
import axios from 'axios';
import * as CONFIG from 'shared-scope/config';
import {
 Icon, Stack, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { formatFileSize } from 'products/common/helpers';
import { fetcher } from 'graphql-api/fetcher';
import { Country, CountryCapability, Route } from 'graphql-api';
import {
 useRequest, Combobox,
} from '@xeebi/neru';
import { z } from 'zod';
import { getSdk } from '../queries.generated';
import { FormField } from '../types';
import { zText } from '../helpers/validators';

const apiCampaign = getSdk(fetcher);

const getCC = async () => apiCampaign.getCountryCapability({
  filter: JSON.stringify({ 'route.id': { $ne: null } }),
});

const getNumbers: any = async ({ countryId, routeId }: GetNumber) => {
  const { data: { data: numbers } } = await axios.get(`${CONFIG.get('api')}/number`, {
    params: {
      filter: {
        country_id: countryId,
        connection_id: routeId,
      },
    },
  });
  return numbers as Number[];
};


export default function StepGeneral({ titleFieldName, noRoute, initial }: StepGeneralProps) {
  const {
    isLoading,
    error: fetchError,
    fetch: fetchCC,
    result: resultCC,
  } = useRequest(getCC);

  const {
    error: fetchNumbersError,
    fetch: fetchNumbers,
    result: numbers,
  } = useRequest(getNumbers);

  const { form: { setValue, setError }, formRow } = useFormContext<StepGeneralRow>();

  const countryCapabilities: CountryCapability[] | null = useMemo(() => resultCC?.countryCapability?.filter((cc: CountryCapability) => cc.route !== null) || null, [resultCC]);

  /**
   * Fetch init data
   */
  useEffect(() => {
    !noRoute && fetchCC();
  }, [fetchCC, noRoute]);

  useEffect(() => {
    const countryId = formRow.route?.country?.id;
    const routeId = formRow?.route?.route?.id;
    if (countryId && routeId) {
      fetchNumbers({ countryId, routeId });
    }
  }, [formRow?.route?.route?.id, formRow.route?.country?.id, fetchNumbers]);
  /**
   * set default route
   */
  useEffect(() => {
    countryCapabilities?.length
    && !formRow.route
    && setValue(FormField.route, countryCapabilities[0]);
  }, [countryCapabilities, formRow.route, formRow.title, setValue]);

  /**
   * set initial values
   */
  useEffect(() => {
    countryCapabilities?.length
    && initial?.route
    && setValue(FormField.route, countryCapabilities
      .find((cc) => cc.route?.id === initial.route?.id && cc.country?.id === initial.country?.id));
  }, [setValue, initial, countryCapabilities]);

  useEffect(() => {
    numbers?.length
    && !formRow.number
    && setValue(FormField.number, numbers[0]);
  }, [numbers, formRow.number, setValue]);
  /**
   * Error handle
   */
  useEffect(() => {
    if (fetchError) {
      setError(FormField.route, fetchError.getMessage());
    }
    if (fetchNumbersError) {
      setError(FormField.number, fetchNumbersError.getMessage());
    }
  }, [fetchError, setError, fetchNumbersError]);

  return (
    <Stack spacing={3}>
      <FormController
        name={FormField.title}
        validateRule={validateRules[FormField.title]}
      >
        {(ctrl) => (
          <TextField
            label={titleFieldName}
            value={ctrl.value || ''}
            onChange={(e) => ctrl.onChange(e.target.value)}
            onBlur={() => ctrl.validate()}
            error={!!ctrl.error}
            helperText={ctrl.error && s(':fieldName can\'t be empty', { fieldName: titleFieldName })}
          />
        )}
      </FormController>
      {!noRoute ? <Stack spacing={2}>
        <Stack spacing={1}>
          <FormController<CountryCapability>
            name={FormField.route}
            validateRule={validateRules[FormField.route]}
          >
            {(ctrl) => {
              const needRoutes = countryCapabilities !== null && countryCapabilities.length === 0
                ? s('You must have at least one route') : '';
              return (countryCapabilities?.length || 0) !== 1 ? (
                <Combobox<CountryCapability>
                  title={s('Route')}
                  options={countryCapabilities || []}
                  optionValue="name"
                  optionKey="id"
                  getOptionLabel={(option) => {
                    if (option && option.country?.name && option.route?.name) {
                      return option.country.name + ' ' + s('via') + ' ' + option.route.name;
                    }
                    return option.route?.name || '';
                  }}
                  value={ctrl.value || null}
                  onChange={(e, newValue) => newValue && ctrl.onChange(newValue)}
                  onBlur={() => ctrl.validate()}
                  loading={isLoading}
                  error={!!ctrl.error || !!needRoutes}
                  helperText={needRoutes || ctrl.error}
                />
              ) : null;
            }}
          </FormController>

          {(countryCapabilities?.length || 0) > 1 ? (
            <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
              <Icon className="icon-file-upload" fontSize="small" />
              <Typography variant="body1">
                {formRow.route?.maxAttachmentSize
                  ? s(
                    'Max size of one attachment for this route is :size',
                    { size: formatFileSize(formRow.route.maxAttachmentSize) },
                  ) : s('Attachments are not allowed on the selected route')}
              </Typography>
            </Stack>
          ) : null}
        </Stack>

        <FormController<Number>
          name={FormField.number}
        >
          {(ctrl) => {
            return (numbers?.length || 0) > 0
              ? (
                <Combobox<Number>
                  title={s('Sender ID')}
                  options={numbers || []}
                  optionValue="number"
                  optionKey="number_id"
                  getOptionLabel={(number) => number.number}
                  value={ctrl.value || null}
                  onChange={(e, newValue) => newValue && ctrl.onChange(newValue)}
                  loading={isLoading}
                  error={!!ctrl.error}
                  helperText={ctrl.error}
                />
              )
              : null;
          }}
        </FormController>
      </Stack> : null}
    </Stack>
  );
}

const validateRules: Record<string, z.ZodSchema> = {
  [FormField.title]: zText(),
  [FormField.route]: z.object({}, s('Route can\'t be empty')),
};

type StepGeneralProps = {
  titleFieldName: string
  noRoute?: boolean
  initial?: { title?: string, route?: Route, country?: Country }
};

export type StepGeneralRow = {
  title?: string
  route?: CountryCapability
  number?: Number
};

type GetNumber = {
  countryId: number
  routeId: number
};

type Number = {
  number: string
  number_id: number
};
