import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { s } from 'i18n';
import { AxiosRequestConfig } from 'axios/index';
import {
  Stack,
} from '@mui/material';
import {
  DataGrid, ApiQueryParams, useGridRef, useRequest, ApiQueryResult,
} from '@xeebi/neru';
import TagButton from 'shared-scope/components/TagButton';
import { useAppContext } from 'shared-scope/AppContext';
import { fetcher } from 'graphql-api/fetcher';
import { TrxType, Module } from 'graphql-api';
import useAlert from 'shared-scope/hooks/useAlert';
import { getSdk } from './query.generated';
import getTrxColumns from './TrxStruct';
import getDocColumns from './DocStruct';

const api = getSdk(fetcher);

const TRX = s('Transactions');
const DOC = s('Documents');

export const getTrx = async (params: ApiQueryParams, config: AxiosRequestConfig) => {
  const { trx, trxCount } = await api.getTrx(params, config);
  return { count: trxCount, rows: trx } as ApiQueryResult;
};

export const getInvoices = async (params: ApiQueryParams, config: AxiosRequestConfig) => {
  const { invoice, invoiceCount } = await api.getInvoices(params, config);
  return { count: invoiceCount, rows: invoice } as ApiQueryResult;
};

export default function Billing() {
  const gridRef = useGridRef();
  const { addError } = useAlert();
  const { currencySign } = useAppContext();

  const [trxTypes, setTrxTypes] = useState<TrxType[]>([]);
  const [modules, setModules] = useState<Module[]>([]);
  const [tab, setTab] = useState(TRX);

  const { error: trxTypeError, fetch: fetchTrxTypes } = useRequest(api.getTrxType);
  const { error: moduleError, fetch: fetchModules } = useRequest(api.getModules);

  const trxColumns = useMemo(() => {
    return getTrxColumns({ trxTypes, currencySign, modules });
  }, [trxTypes, currencySign, modules]);

  const docColumns = useMemo(() => {
    return getDocColumns({ currencySign });
  }, [currencySign]);

  useEffect(() => {
    const getTypes = async () => {
      const data = await fetchTrxTypes();
      data?.trxType && setTrxTypes(data?.trxType);
    };
    const getModules = async () => {
      const data = await fetchModules();
      data?.module && setModules(data?.module);
    };
    getTypes();
    getModules();
  }, [fetchModules, fetchTrxTypes]);

  useEffect(() => {
    const error = moduleError || trxTypeError;
    trxTypeError && addError(error.getMessage());
  }, [trxTypeError, moduleError, addError]);

  return (
    <Stack spacing={2}>
      <Stack
        spacing={1}
        direction="row"
        flexWrap="wrap"
        useFlexGap
      >
        <TagButton
          onClick={() => setTab(TRX)}
          isActive={tab === TRX}
        >
          {TRX}
        </TagButton>
        <TagButton
          onClick={() => setTab(DOC)}
          isActive={tab === DOC}
        >
          {DOC}
        </TagButton>
      </Stack>

      {tab === TRX
        ? (
          <DataGrid
            primaryKey="id"
            storageId="BillingTrxStorage"
            pageSize={20}
            apiRef={gridRef}
            getRows={getTrx}
            columns={trxColumns}
            rowHeight={50}
          />
        )
        : null}

      {tab === DOC
        ? (
          <DataGrid
            primaryKey="id"
            storageId="BillingTrxStorage"
            pageSize={20}
            apiRef={gridRef}
            getRows={getInvoices}
            columns={docColumns}
          />
        )
        : null}
    </Stack>
  );
}
