import { QuestionsTemplate } from 'products/common/types';
import cloneDeep from 'lodash/cloneDeep';
import { s } from 'i18n';
import { Attachment } from 'graphql-api';
import validateAttachment from 'products/common/CampaignShared/helpers/validateAttachment';


export default function validateQuestions(
  questions: QuestionsTemplate | null,
  attachmentList?: Attachment[],
  maxAttachmentSize?: number,
  isResponder?: boolean,
) {
  const validQuestions = cloneDeep(questions);
  let questionsError = '';

  const validAttachment = (attachmentId: string): string => validateAttachment({
    attachmentId,
    maxAttachmentSize,
    attachmentList,
  });

  if (!validQuestions) {
    questionsError = s('Fill questions fields');
  } else {
    if (!isResponder) {
      validQuestions.initialError = {
        question: !questions?.initialMessage?.trim() ? s('Engagement question can\'t be empty. ') : '',
        attachment: questions?.initialAttachment?.id ? validAttachment(questions.initialAttachment.id) : '',
      };
      validQuestions.initialError.question += !questions?.initialAnswer?.trim() ? s('Answer to Engagement question can\'t be empty.') : '';
    }

    questionsError = validQuestions.initialError?.question
      ? s('Incorrectly filled in questions') : questionsError;
    questionsError = !questions?.template?.questions?.length
      ? s('You have to add at least one question to the poll') : questionsError;

    validQuestions.template.questions = questions?.template?.questions?.map((q) => {
      questionsError = !q.question?.trim() ? s('Incorrectly filled in questions') : questionsError;
      return {
        ...q,
        ...{
          error: {
            question: !q.question?.trim() ? s('You must set a text for each question') : '',
            attachment: q.attachment_id ? validAttachment(q.attachment_id) : '',
          },
        },
      };
    });

    validQuestions.template.final_error = {
      question: !questions?.template?.final_message ? s('You must set a text for final message') : '',
      attachment: questions?.template.final_attachment_id ? validAttachment(questions?.template.final_attachment_id) : '',
    };
    questionsError = validQuestions.template.final_error.question
      ? s('Incorrectly filled in questions') : questionsError;
  }

  return {
    questions: validQuestions,
    error: questionsError,
  };
}
