import {
  Stack, TextField, Typography,
} from '@mui/material';
import * as Encoding from 'shared-scope/helpers/encoding';
import { s } from 'i18n';
import { Segment } from 'shared-scope/types';
import { OutlinedTextFieldProps } from '@mui/material/TextField/TextField';
import AiRephrase from 'shared-scope/components/AiRephrase';
import * as CONFIG from 'shared-scope/config';
import { useAppContext } from 'shared-scope/AppContext';
import {
  useEffect, useMemo, useState,
} from 'react';
import { forOwn } from 'lodash';

export default function Message(
  {
    title = s('Message text'),
    value,
    long = false,
    encodings,
    errorText,
    onChangeValue,
    isMMS = false,
    ...other
  }: MessageProps) {
  const { user } = useAppContext();

  const [error, setError] = useState<MessageError>({});

  useEffect(() => {
    setError((e) => ({ ...e, ...{ init: errorText } }));
  }, [errorText]);

  const smsCounter = useMemo(() => {
    let splitData: Segment | null;
    if (long) {
      const enc = Encoding.selectEncoding(value, encodings || undefined);

      splitData = {
        name: enc?.name?.toString(),
        segments: [value],
        per_segment: value.length,
        total: 1,
      };
    } else {
      splitData = Encoding.splitSegments(value, encodings);
    }

    if (!splitData?.name || !splitData.segments) {
      setError((e) => ({ ...e, ...{ encod: s('No encoding available') } }));
      return '';
    }
      const {
        name,
        segments,
        per_segment: perSegment,
        total = 0,
      } = splitData;
      return `${total || value.length}/${segments.length * perSegment} (${segments.length} ${s('SMS')}, ${name.toUpperCase()})`;
  }, [long, value, encodings]);

  const messageError = useMemo(() => {
    const errList: string[] = [];
    forOwn(error, (e) => e && errList.push(e));
    return errList.length ? errList.join('\n') : '';
  }, [error]);

  return (
    <Stack spacing={1} flexGrow={1} sx={{ width: '100%' }}>
      <TextField
        label={title}
        value={value}
        error={!!messageError}
        helperText={messageError}
        multiline
        maxRows={10}
        onChange={(e) => onChangeValue(e.target.value)}
        {...other}
      />
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Typography variant="subtitle1">{isMMS ? s('MMS') : smsCounter}</Typography>
        {CONFIG.get('tmpl_generator_enabled', false)
          && user.customer?.profile?.aiEnable
          && <AiRephrase
            value={value}
            onChange={(text) => onChangeValue(text)}
            onError={(err) => setError((e) => ({ ...e, ...{ ai: err } }))}
            disabled={!value || !!error.init || !!error.encod}
          />}
      </Stack>
    </Stack>
  );
}

interface MessageProps extends OutlinedTextFieldProps {
  title?: string
  value: string
  long?: boolean
  encodings?: Encoding.Encod[]
  errorText?: string
  onChangeValue: (v: string) => void
  isMMS?: boolean
}

type MessageError = {
  init?: string
  encod?: string
  ai?: string
};
