import { fetcher } from 'graphql-api/fetcher';
import { getSdk, GetTemplatesQuery } from 'shared-scope/queries.generated';
import { Combobox, useRequest } from '@xeebi/neru';
import React, {
 useEffect, useMemo, useState,
} from 'react';
import { FormController, useFormContext } from 'shared-scope/components/Form';
import {
 Box, Stack, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { Maybe } from 'shared-scope/types';
import { ConversationTemplate } from 'graphql-api';
import { s } from 'i18n';
import { QuestionsTemplate } from 'products/common/types';
import { find, sample } from 'lodash';
import { FormRowsErrors } from 'shared-scope/components/Form/types';
import { z } from 'zod';
import useAttachments from 'shared-scope/hooks/useAttachments';
import { Questions, PhonePreview } from '../components';
import {
  FormField, Sources, TemplateType,
} from '../types';
import {
  isCorrectQuestions, getQuestionsError,
} from '../helpers';


const api = getSdk(fetcher);

const getTemplates = async () => api.getTemplates({
  filter: JSON.stringify({ type: TemplateType.Poll }),
});

export default function StepQuestions(
  {
    sourceType,
    maxAttachmentSize,
    templateId,
  }: StepQuestionsProps) {
  const {
    isLoading,
    error: fetchError,
    fetch: fetchTemplates,
    result: templates,
  } = useRequest<GetTemplatesQuery>(getTemplates);

  const { attachmentList } = useAttachments();

  const {
    form: {
      setValue,
      getValue,
      setError,
      getError,
      validateFields,
    },
    formRow: { template, questions },
  } = useFormContext<StepQuestionRow>();

  const [questionType, setQuestionType] = useState(QuestionsType.Template);

  /**
   * Fetch templates & attachments list
   */
  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  /**
   * Error handle
   */
  useEffect(() => {
    setError(FormField.template, fetchError ? fetchError.getMessage() : '');
  }, [fetchError, setError]);

  const questionsRule = useMemo(
    () => isCorrectQuestions(attachmentList, maxAttachmentSize, { message: sourceType !== Sources.responder }),
    [attachmentList, maxAttachmentSize, sourceType],
  );

  /**
   * Set template
   */
  useEffect(() => {
    if (templateId && templates?.conversationTemplate) {
      const t = find(templates.conversationTemplate, { id: templateId });
      t && setValue(FormField.template, t);
      setValue(FormField.questions, t as QuestionsTemplate);
    }
  }, [templates?.conversationTemplate, templateId, setValue]);

  /**
   * Revalidate fields on change Route
   */
  useEffect(() => {
    validateFields(
      FormField.questions,
      { [FormField.questions]: isCorrectQuestions(attachmentList, maxAttachmentSize, { message: false, answer: false }) },
    );
  }, [maxAttachmentSize, attachmentList, validateFields]);

  useEffect(() => {
    const tmpl = getValue(FormField.template);
    const qst = getValue(FormField.questions);

    if (tmpl) {
      setQuestionType(QuestionsType.Template);
    } else if (qst) {
      setQuestionType(QuestionsType.Manual);
    }
  }, [getValue]);

  const err = getError(FormField.questions) as FormRowsErrors;
  const attErrors = getQuestionsError(err);

  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      useFlexGap
    >
      <Box>
        <ToggleButtonGroup
          value={questionType}
          onChange={(e, v) => {
            setValue(FormField.template, null);
            setValue(FormField.questions, null);
            return v !== null && setQuestionType(v);
          }}
          exclusive
          aria-label="questions-group"
        >
          <ToggleButton value={QuestionsType.Template}>{s('Template')}</ToggleButton>
          <ToggleButton value={QuestionsType.Manual}>{s('Manual')}</ToggleButton>
        </ToggleButtonGroup>
        <Stack spacing={3} sx={{ mt: 3 }}>
          { questionType === QuestionsType.Template
            && <FormController<Maybe<ConversationTemplate>>
              name={FormField.template}
              validateRule={isSetQuestion}
            >
              {(ctrl) => (
                <Combobox<ConversationTemplate>
                  title={s('Poll template')}
                  value={ctrl.value || null}
                  options={templates?.conversationTemplate || []}
                  onChange={(e, v) => {
                    ctrl.onChange(v);
                    setValue(FormField.questions, v as QuestionsTemplate);
                  }}
                  loading={isLoading}
                  error={!!ctrl.error}
                  helperText={ctrl.error}
                  optionKey="id"
                  optionValue="name"
                  sx={{ width: '600px' }}
                />
              )}
            </FormController>}
          { (template || questionType === QuestionsType.Manual)
            && <FormController<Maybe<QuestionsTemplate>>
              name={FormField.questions}
              validateRule={questionsRule}
            >
              {(ctrl) => (
                <Questions
                  value={ctrl.value}
                  onChange={async (v, templateChanged) => {
                    if (QuestionsType.Manual !== questionType && templateChanged) {
                      setValue(FormField.template, null);
                      setQuestionType(QuestionsType.Manual);
                      delete v.id;
                    }
                    ctrl.onChange(v);
                  }}
                  maxAttachmentSize={maxAttachmentSize}
                  isResponder={sourceType === Sources.responder}
                  error={ctrl.fullError}
                />
              )}
            </FormController>}
        </Stack>
      </Box>
      <Box>
        <PhonePreview
          initialMessage={questions?.initialMessage || ''}
          initialAnswer={questions?.initialAnswer
            ? sample(questions?.initialAnswer.split(' ')) || null
            : null}
          initialAttachment={!attErrors.initial.attachment
            ? questions?.initialAttachment?.id || null
            : null}
          finalMessage={questions?.template?.final_message || null}
          finalAttachment={!attErrors.final.attachment
            ? questions?.template?.final_attachment_id || null
            : null}
          questions={questions?.template?.questions?.map(
            (q, i) => ({
                ...q,
                ...{
                  attachment_id: q.key && !attErrors.questions[i]?.attachment
                    ? q.attachment_id : null,
                },
              }),
          ) || []}
        />
      </Box>
    </Stack>
  );
}

export type StepQuestionRow = {
  template?: ConversationTemplate
  questions?: QuestionsTemplate
};

type StepQuestionsProps = {
  sourceType?: Sources
  maxAttachmentSize?: number
  templateId?: Maybe<number>
};

enum QuestionsType {
  Template,
  Manual,
}

const isSetQuestion = (row: StepQuestionRow) => z.custom(
  () => !!row.template || !!row.questions,
  s('Select a template or enter questions manually'),
);
