import { Box, Stack } from '@mui/material';
import { FormController, useFormContext } from 'shared-scope/components/Form';
import { ConversationTemplate } from 'graphql-api';
import { s } from 'i18n';
import React, {
 useCallback, useEffect, useMemo, useState,
} from 'react';
import { fetcher } from 'graphql-api/fetcher';
import { getSdk, GetTemplatesQuery } from 'shared-scope/queries.generated';
import { useRequest, Combobox } from '@xeebi/neru';
import { Maybe } from 'shared-scope/types';
import { find } from 'lodash';
import useAttachments from 'shared-scope/hooks/useAttachments';
import {
 checkAttachmentSize, getMessageError, isCorrectMessage,
} from '../helpers';
import { MessageShortcuts, Question, PhonePreview } from '../components';
import {
  FormField, Mapping, MappingFormValue, QuestionValue, SourceValue, TemplateType,
} from '../types';


const api = getSdk(fetcher);

const getTemplates = async () => api.getTemplates({
  filter: JSON.stringify({ type: TemplateType.Text }),
});

export default function StepMessage({
 mapping, sourceValue, maxAttachmentSize, templateId, mappingParams,
}: StepMessagesProps) {
  const {
    isLoading,
    error: fetchError,
    fetch: fetchTemplates,
    result: templates,
  } = useRequest<GetTemplatesQuery>(getTemplates);

  const { attachmentList } = useAttachments();

  const {
    form: {
      setValue, setError, getError, validateFields,
    },
    formRow,
  } = useFormContext<StepMessageRow>();

  const [preview, setPreview] = useState('');

  const setMessage = useCallback((m: string) => {
    setValue(FormField.message, { ...formRow.message, text: m });
  }, [setValue, formRow.message]);

  /**
   * Fetch templates & attachments list
   */
  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  /**
   * Error handle
   */
  useEffect(() => {
    setError(FormField.template, fetchError ? fetchError.getMessage() : '');
  }, [fetchError, setError]);

  /**
   * Set phone preview
   */
  useEffect(() => {
    const mappingValues: Record<string, string> = {};
    const previewMapping = mapping || {};
    const delimiter = mappingParams?.delimiter || ',';
    const rowSet = (
      sourceValue?.data?.data_rowset
      || sourceValue?.data?.data_lines?.split('\n').filter((row) => row).map((row) => row.split(delimiter))
      || []
    );
    for (const row of rowSet) {
      for (const [key, index] of Object.entries(previewMapping)) {
        if (row[index]) {
          mappingValues[key] = row[index];
        }
      }
    }
    let newPreview = formRow.message?.text || '';
    for (const key of Object.keys(previewMapping)) {
      const value = mappingValues[key] || '';
      newPreview = newPreview.replaceAll(`[${key}]`, value);
    }
    setPreview(newPreview);
  }, [formRow.message, mapping, sourceValue, mappingParams]);

  /**
   * Set template
   */
  useEffect(() => {
    if (templateId && templates?.conversationTemplate) {
      const t = find(templates.conversationTemplate, { id: templateId });
      t && setValue(FormField.template, t);
      t && setValue(FormField.message, {
        text: t.initialMessage || '',
        attachment: t.initialAttachment?.id || null,
      });
    }
  }, [templates?.conversationTemplate, templateId, setValue]);

  const questionRule = useCallback(
    (onlyAtt: boolean = false) => isCorrectMessage(
      attachmentList,
      maxAttachmentSize,
      { message: !onlyAtt, answer: false },
    ),
    [attachmentList, maxAttachmentSize],
  );

  useEffect(() => {
   validateFields(FormField.message, { [FormField.message]: questionRule(true) });
  }, [maxAttachmentSize, validateFields, questionRule]);

  const attError = getMessageError(getError(FormField.message)).attachment;

  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
      useFlexGap
    >
      <Stack spacing={2} sx={{ width: 600 }}>
        <FormController<Maybe<ConversationTemplate>> name={FormField.template}>
          {(ctrl) => (
            <Combobox<ConversationTemplate>
              title={s('Campaign template')}
              value={ctrl.value || null}
              options={templates?.conversationTemplate || []}
              onChange={(e, v) => {
                ctrl.onChange(v);
                setValue<QuestionValue>(
                  FormField.message,
                  {
                    text: v?.initialMessage || '',
                    attachment: v?.initialAttachment?.id || null,
                  },
                );
              }}
              loading={isLoading}
              error={!!ctrl.error}
              helperText={ctrl.error}
              optionKey="id"
              optionValue="name"
            />
        )}</FormController>
        {mapping && <MessageShortcuts
          mapping={mapping}
          onShortcut={(shortcut: string) => setMessage(`${formRow.message?.text || ''} [${shortcut}]`)}
        />}
        <FormController<QuestionValue>
          name={FormField.message}
          validateRule={useMemo(() => questionRule(), [questionRule])}
        >
          {(ctrl) => (
            <Question
              title={s('Message')}
              question={ctrl.value?.text || ''}
              onChange={(v) => {
                ctrl.onChange({
                  text: v,
                  attachment: ctrl.value?.attachment,
                });
                setValue(FormField.template, null);
              }}
              onBlur={() => ctrl.validate()}
              attachment={ctrl.value?.attachment}
              onAttachmentChange={(v) => {
                ctrl.onChange({
                  text: ctrl.value?.text || '',
                  attachment: v,
                });
                setValue(FormField.template, null);
              }}
              maxAttachmentSize={maxAttachmentSize}
              error={ctrl.fullError ? getMessageError(ctrl.fullError) : undefined}
            />
          )}
        </FormController>
      </Stack>
      <Box>
        <PhonePreview
          initialMessage={preview}
          initialAnswer={null}
          initialAttachment={checkAttachmentSize(maxAttachmentSize)
          && formRow.message?.attachment
          && !attError
            ? formRow.message.attachment : null}
          finalMessage={null}
          finalAttachment={null}
          questions={[]}
          height={250}
        />
      </Box>
    </Stack>
  );
}

export type StepMessageRow = {
  template?: ConversationTemplate
  message?: QuestionValue
};

type StepMessagesProps = {
  mapping?: Mapping
  mappingParams?: MappingFormValue
  sourceValue?: SourceValue
  maxAttachmentSize?: number
  templateId?: Maybe<number>
};
