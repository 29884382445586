import { Button, Stack, Typography } from '@mui/material';
import { s } from 'i18n';
import { fetcher } from 'graphql-api/fetcher';
import { useEffect, useState } from 'react';
import { USA } from 'shared-scope/const';
import { Region } from 'graphql-api';
import { useRequest, useMountedState } from '@xeebi/neru';
import range from 'lodash/range';
import { getSdk } from 'products/common/queries.generated';
import ErrorText from './ErrorText';
import { ContactListFilter } from '../types';
import { US_TIME_ZONES } from '../helpers/const';
import MappingParam, { MappingParamType } from './MappingParam';

const api = getSdk(fetcher);

export default function FilterForm({ value, onChange }: FilterFormProps) {
  const isMounted = useMountedState();
  const { fetch, error } = useRequest(api.getRegion);
  const [
    regions,
    setRegions,
  ] = useState<MappingParamType[]>([]);

  useEffect(() => {
      const loadRegions = async () => {
        const { region } = await fetch({
          filter: `{"country.id": ${USA}}`,
          sort: '[{"code": 1}]',
        });
        const newRegions: MappingParamType[] = region.map((r: Region) => ({ k: r.code || 'unknown', v: r.name || 'unknown' }));
        isMounted() && setRegions(newRegions);
      };
      isMounted() && loadRegions();
    }, [isMounted, fetch]);

  return <Stack spacing={1}>
    <Typography variant="h4">{s('Filters')}</Typography>
    <MappingParam<string>
      title={s('Gender')}
      value={value.gender || ''}
      type={[
        { k: '', v: '' },
        { k: 'M', v: 'M' },
        { k: 'F', v: 'F' },
      ]}
      inputWidth="180px"
      onChange={(v) => onChange({ ...value, gender: v })}
    />
    <MappingParam<string[]>
      title={s('States')}
      value={value.state || []}
      type={regions}
      inputWidth="180px"
      onChange={(v) => onChange({ ...value, state: v })}
      multiple
      canPaste
    />
    {error && <ErrorText>{error}</ErrorText>}
    <MappingParam<string[]>
      title={s('Time zones')}
      value={value.tz || []}
      type={US_TIME_ZONES}
      inputWidth="180px"
      onChange={(v) => onChange({ ...value, tz: v })}
      multiple
    />
    <MappingParam<string>
      title={s('No campaigns last')}
      value={value.campaign || ''}
      type={getLastCampaignIntervals()}
      inputWidth="180px"
      onChange={(v) => onChange({ ...value, campaign: v })}
    />
    <Button
      variant="outlined"
      onClick={() => onChange({})}
      size="small"
      disabled={!(value.gender || value.state?.length || value.tz?.length || value.campaign)}
      sx={{ maxWidth: 150 }}
    >
      {s('Clear filters')}
    </Button>
  </Stack>;
}

type FilterFormProps = {
  value: ContactListFilter
  onChange: (v: ContactListFilter) => void
};

function getLastCampaignIntervals():{ k: string, v: string }[] {
  const h = range(1, 5);
  const hours = h.map((hh) => ({ k: `${hh}h`, v: (hh === 1) ? `${hh} hour` : `${hh} hours` }));

  const d = range(1, 16);
  d.push(30, 180, 360);
  const days = d.map((dd) => ({ k: `${dd}d`, v: (dd === 1) ? `${dd} day` : `${dd} days` }));

  return [...hours, ...days];
}
