import {
  Autocomplete, Box, Checkbox, FormControlLabel,
  Stack, TextField, ToggleButton, ToggleButtonGroup,
} from '@mui/material';
import { s } from 'i18n';
import { FormController, useFormContext } from 'shared-scope/components/Form';
import { PhoneBook, PhoneBookFolder } from 'graphql-api';
import React, {
  ReactNode, useEffect, useMemo, useState,
} from 'react';
import { isEqual, isUndefined } from 'lodash';
import { z } from 'zod';
import { StepTargetRow } from 'products/shared/Campaign/steps/types';
import { ErrorText } from 'products/shared/Campaign/components';
import SourceFile from 'products/shared/Campaign/source/SourceFile';
import SourceText from 'products/shared/Campaign/source/SourceText';
import SourcePhoneBook from 'products/shared/Campaign/source/SourcePhoneBook';
import SourceCampaign from 'products/shared/Campaign/source/SourceCampaign';
import {
  CampaignType,
  ContactListFilter,
  FormField,
  SourceCampaignValue,
  SourceFileValue,
  SourcePhoneBookValue,
  Sources,
  SourceTextValue,
  SourceValue,
} from '../types';


const campaignTypes = [
  {
    k: CampaignType.default,
    v: s('Simple campaign'),
  },
  {
    k: CampaignType.drip,
    v: s('Drip'),
  },
];

export default function StepTarget(
  {
    avail = [Sources.excel, Sources.text, Sources.phonebook],
    noValidate,
    country,
    campaignId,
    targetType,
    useParticipants = false,
  }: StepTargetProps) {
  const {
    form: { setValue },
    formRow,
  } = useFormContext<StepTargetRow>();

  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState('');
  const [folder, setFolder] = useState<PhoneBookFolder | null>(null);
  const [phonebook, setPhonebook] = useState<PhoneBook | null>(null);
  const [filter, setFilter] = useState<ContactListFilter>({});

  const [type, setType] = useState<CampaignType>(formRow.type || CampaignType.default);
  const [sendToExistingContacts, setSendToExistingContacts] = useState<boolean>(false);

  const tabs = useMemo<Record<Sources, ReactNode>>(() => ({
    [Sources.excel]: <ToggleButton value={Sources.excel} key={Sources.excel}>{s('File')}</ToggleButton>,
    [Sources.text]: <ToggleButton value={Sources.text} key={Sources.text}>{s('Text')}</ToggleButton>,
    [Sources.phonebook]: <ToggleButton value={Sources.phonebook} key={Sources.phonebook}>{s('Phone book')}</ToggleButton>,
    [Sources.responder]: <ToggleButton value={Sources.responder} key={Sources.responder}>{s('Responder')}</ToggleButton>,
    [Sources.campaign]: null,
  }), []);

  useEffect(() => {
    if (!['poll', 'campaign'].includes(targetType) || campaignId) {
      return;
    }
    if (type === CampaignType.drip) {
      setValue<Sources>(FormField.sourceType, Sources.phonebook);
    }
    setValue(FormField.type, type);
  }, [type, targetType, campaignId, setValue]);

  const content = useMemo<Record<Sources, ReactNode>>(() => ({
    [Sources.excel]: <FormController<SourceFileValue>
      name={FormField.sourceValue}
      render={({ onChange }) => <SourceFile file={file} onFile={setFile} onChange={onChange} />}
    />,
    [Sources.text]: <FormController<SourceTextValue>
      name={FormField.sourceValue}
      render={({ onChange }) => <SourceText text={text} onText={setText} onChange={onChange} />}
    />,
    [Sources.phonebook]: <FormController<SourcePhoneBookValue>
      name={FormField.sourceValue}
      render={({ onChange }) => <SourcePhoneBook
        folder={folder}
        onFolder={setFolder}
        phonebook={phonebook}
        onPhonebook={setPhonebook}
        onChange={onChange}
        filter={filter}
        country={country}
        type={type}
      />}
    />,
    [Sources.responder]: null,
    [Sources.campaign]: <FormController<SourceCampaignValue> name={FormField.sourceValue}>
      {({ onChange }) => <SourceCampaign campaignId={campaignId || 0} useParticipants={useParticipants} onChange={onChange} />}
    </FormController>,
  }), [filter, country, file, text, folder, phonebook, campaignId, useParticipants, type]);

  useEffect(() => {
    setValue(FormField.sendToExistingContacts, sendToExistingContacts);
  }, [sendToExistingContacts, setValue]);

  /**
   * Reset the value when the resource is changed
   */
  useEffect(() => {
    setValue<SourceValue>(FormField.sourceValue, null);
  }, [formRow.source_type, setValue]);

  /**
   * If filter changed
   */
  useEffect(() => {
     !isUndefined(formRow.filter)
     && !isEqual(filter, formRow.filter)
     && setFilter(formRow.filter || {});
  }, [formRow.filter, filter]);

  return (
    <Stack spacing={3}>
      <FormController<Sources>
        name={FormField.sourceType}
        validateRule={!noValidate ? isSetSourceValue : undefined}
        initial={avail.length ? avail[0] : undefined}
      >
        {({ value, onChange, error }) => (
          <Stack spacing={2}>
            {(targetType === 'campaign' || targetType === 'poll') && !campaignId
              ? (
                <Autocomplete
                  value={campaignTypes.find((t) => t.k === type)}
                  options={campaignTypes}
                  getOptionLabel={(option) => option.v}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(event, t) => t && setType(t.k)}
                />
              )
              : null}

            {(avail.length > 1 && type === CampaignType.default)
              ? <ToggleButtonGroup
                  value={value}
                  onChange={(e, v) => v && onChange(v)}
                  exclusive
                  aria-label="source-group"
              >
                {avail.map((src) => tabs[src] || null)}
              </ToggleButtonGroup> : null }
            {error && <ErrorText>{error}</ErrorText> }
          </Stack>
        )}
      </FormController>

      <Box sx={{ maxWidth: '600px' }}>
        {formRow.source_type ? content[formRow.source_type] : null}
      </Box>

      {type === CampaignType.drip
        ? (
          <FormControlLabel
            label={s('Send messages to contacts already in the phonebook')}
            control={<Checkbox
              value={sendToExistingContacts}
              onChange={(e) => setSendToExistingContacts(e.target.checked)}
            />}
          />
        )
        : null}
    </Stack>
  );
}

type StepTargetProps = {
  avail?: Sources[]
  noValidate?: boolean
  useParticipants?: boolean
  country?: number | null
  campaignId?: number
  targetType: 'poll' | 'campaign' | 'phone-book' | 'opt-in'
};

const isSetSourceValue = (row: StepTargetRow) => z.custom(
    () => !!row.source_value?.data || row.source_type === Sources.responder,
    s('Please specify target audience'),
  );
