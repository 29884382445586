import GrayBox from 'shared-scope/components/Box/GrayBox';
import {
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AttachmentSize, QuestionError } from 'products/common/types';
import TagBox from 'shared-scope/components/TagBox';
import Message from './Message';
import AttachmentPreview from './AttachmentPreview';
import { checkAttachmentSize } from '../helpers';


type QuestionProps = {
  position?: number | null | undefined
  title: string
  question: string
  answerTitle?: string | null | undefined
  answer?: string | null | undefined
  attachment?: string | null | undefined
  onChange: (question: string) => void
  onBlur?: () => void
  onDelete?: (() => void) | undefined
  onAnswerChange?: ((answer: string) => void) | undefined
  onAttachmentChange?: ((attachment: string | null) => void) | undefined
  skipQuestion?: boolean
  maxAttachmentSize?: AttachmentSize
  error?: QuestionError
};

export default function Question({
  position,
  title,
  question,
  answerTitle,
  answer,
  attachment,
  onChange,
  onDelete,
  onBlur,
  onAnswerChange,
  onAttachmentChange,
  skipQuestion = false,
  maxAttachmentSize,
  error,
}: QuestionProps) {
  const { palette } = useTheme();

  return <GrayBox
    sx={{ maxWidth: '1000px' }}
  >
    <Stack alignItems="flex-start" spacing={1} sx={{ width: '100%' }}>
      <Stack direction="row" spacing={2} alignItems="flex-start" sx={{ width: '100%' }}>
        {
          position === undefined || position === null ? null : (
            <Typography variant="h4">{position}</Typography>
          )
        }
        <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
          {!skipQuestion
            ? (
              <Message
                value={question}
                onChangeValue={(v) => onChange(v)}
                title={title}
                errorText={error?.question}
                variant="outlined"
                onBlur={() => onBlur?.()}
                isMMS={checkAttachmentSize(maxAttachmentSize) && !!attachment}
              />
            )
            : null}

          {
            onAnswerChange ? (
              <TagBox
                value={answer || ''}
                onChange={(v) => onAnswerChange(v as string)}
                textField={{
                  label: answerTitle || '',
                  variant: 'outlined',
                  sx: { width: '250px' },
                  error: !!error?.reply,
                  helperText: error?.reply,
                }}
              />
            ) : null
          }
        </Stack>
        {
          onDelete ? (
            <IconButton
              aria-label="Delete question"
              sx={{
                color: palette.error.main,
                borderRadius: '8px',
                '&:hover': { backgroundColor: palette.primary.light },
              }}
              onClick={() => onDelete()}
            >
              <div><i className="icon-delete" /></div>
            </IconButton>
          ) : null
        }
      </Stack>
      <Stack alignItems="flex-start" pl="30px" spacing={1}>
        {
          (onAttachmentChange && !skipQuestion && (checkAttachmentSize(maxAttachmentSize) || attachment)) ? (
            <AttachmentPreview
              attachment={attachment}
              onChange={onAttachmentChange}
              maxFileSize={maxAttachmentSize}
              error={error?.attachment}
            />
          ) : null
        }
      </Stack>
    </Stack>
  </GrayBox>;
}
