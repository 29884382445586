import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { useRequest } from '@xeebi/neru';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { every, isEqual } from 'lodash';
import {
  FormField, request, SchedulerLabel, Sources,
} from 'products/shared/Campaign';
import {
  StepGeneral,
  StepScheduler,
  StepSettings,
  StepSettingsReadOnly,
  StepTarget,
  StepTargetReadOnly,
  StepQuestions,
  NewPollRow,
} from 'products/shared/Campaign/steps';
import { s } from 'i18n';
import Page from 'layout/Page';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import { CountryCapability } from 'graphql-api';
import { Button, Stack, Typography } from '@mui/material';
import { GetCampaignsQuery, getSdk } from 'products/shared/Campaign/queries.generated';
import { fetcher } from 'graphql-api/fetcher';
import { getCampaignData } from 'products/Poll/helpers';
import ActionsBox from 'shared-scope/components/Box/ActionsBox';
import { CampaignStatus } from 'products/CampaignList/types';
import { CAMPAIGN_TYPE_DRIP } from 'products/common/helpers';


const apiCampaign = getSdk(fetcher);
const back = '/poll';

export default function EditPoll() {
  const { pollId = 0 } = useParams();
  const form = useForm<NewPollRow>();
  const navigate = useNavigate();

  const [hasError, setHasError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [editSource, setEditSource] = useState(false);

  const setInitial = useMemo(() => form.setInitial, [form]);

  const [schedulerOn, setSchedulerOn] = useState(false);
  const [scheduleTimes, setScheduleTimes] = useState<string | null>(null);

  const {
    addSuccess,
    addError,
  } = useAlert();

  const {
    isLoading,
    error: errorCreate,
    fetch: createPoll,
    result,
  } = useRequest(request.createCampaign);

  const {
    isLoading: isLoadingCampaign,
    error: errorCampaign,
    fetch: fetchPoll,
    result: polls,
  } = useRequest<GetCampaignsQuery>(apiCampaign.getCampaigns);

  const {
    result: updateResult,
    isLoading: isUpdating,
    error: errorUpdate,
    fetch: updatePoll,
  } = useRequest(request.updatePoll);

  const {
    result: updateStatusResult,
    isLoading: isStatusUpdating,
    error: errorUpdateStatus,
    fetch: updateStatus,
  } = useRequest(request.updateCampaignStatus);

  useEffect(() => {
    if (hasError) {
      const errors = form.getErrors();
      const names = Object.keys(errors);
      if (names.length) {
        const el = document.getElementsByClassName(`formController-${names[0]}`);
        el?.[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      setHasError(false);
    }
  }, [hasError, form]);

  useEffect(() => {
    const error = errorCreate || errorCampaign || errorUpdate || errorUpdateStatus;
    error && addError(error.getMessage());
  }, [errorCreate, errorCampaign, errorUpdate, errorUpdateStatus, addError]);

  useEffect(() => {
    result && addSuccess(s('New poll added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  useEffect(() => {
    (updateStatusResult || updateResult) && addSuccess(s('Poll has been successfully edited'));
    (updateStatusResult || updateResult) && navigate(back);
  }, [updateStatusResult, updateResult, navigate, addSuccess]);

  useEffect(() => {
    pollId && fetchPoll({
      filter: JSON.stringify({ id: { $eq: +pollId } }),
    });
  }, [fetchPoll, pollId]);

  const poll = useMemo(
    () => (polls?.campaign?.length ? polls.campaign[0] : null),
    [polls?.campaign],
  );

  const onDraft = useCallback(async () => {
    if (!form.validate()) {
      setHasError(true);
      return;
    }
    const row = form.getRow();
    if (!row || !poll) {
      return;
    }
    if (editSource) {
      await createPoll(getCampaignData(row, schedulerOn, true, +pollId));
    } else {
      await updatePoll({ poll, schedulerOn, newPoll: row });
    }
  }, [pollId, schedulerOn, createPoll, form, editSource, poll, updatePoll]);

  const onSubmit = useCallback(async () => {
    if (hasChanges) {
      await onDraft();
    }
    await updateStatus({ campaignId: pollId, status: CampaignStatus.Stopped });
  }, [pollId, updateStatus, onDraft, hasChanges]);

  useEffect(() => {
    setSchedulerOn(poll?.scheduleEnabled || false);
    const useparticipants = poll?.type === CAMPAIGN_TYPE_DRIP
      ? { send_to_existing_contacts: poll.params?.send_to_existing_contacts || false }
      : {};

    poll && (
      setInitial({
        title: poll.name || '',
        source_type: poll.params?.campaign_source_type,
        source_name: poll.params?.campaign_source_name || null,
        questions: {
          initialMessage: poll.messageTemplates?.[0]?.text || '',
          initialAnswer: poll.params.reply_actions?.[0]?.keywords || '',
          initialAttachment: { id: poll.attachmentId || null },
          template: poll.conversationTemplate,
        },
        schedule_times: poll.scheduleTimes || '',
        mapping: poll.params?.mapping || {},
        type: poll?.type || undefined,
        ...useparticipants,
      })
    );
  }, [poll, setInitial]);

  useEffect(() => {
    const st = (form.getValue(FormField.route) as CountryCapability)?.scheduleTimes || null;
    setScheduleTimes(st);
    if (st) {
      setSchedulerOn(true);
    }
  }, [form]);

  useEffect(() => {
    if (!poll) {
      return;
    }
    const newPoll = form?.getRow();

    const checkChanges = !every([
      (newPoll?.title === poll.name),
      (newPoll?.route?.route?.id === poll.route?.id),
      (newPoll?.questions?.initialMessage === poll.messageTemplates?.[0]?.text),
      (newPoll?.questions?.initialAnswer === poll.params.reply_actions?.[0]?.keywords),
      (newPoll?.questions?.initialAttachment?.id === poll.attachmentId),
      isEqual(newPoll?.questions?.template, poll.conversationTemplate),
      (schedulerOn === poll.scheduleEnabled),
      (newPoll?.schedule_times === poll.scheduleTimes),
    ], Boolean) || editSource;

    setHasChanges(checkChanges);
  }, [form, poll, schedulerOn, editSource]);

  return (
    <Page
      title={s('Edit poll :name', { name: poll?.name })}
      back={back}
      loading={isLoading || isLoadingCampaign || isUpdating || isStatusUpdating}
    >
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>

          <Step label={s('General')}>
            <StepGeneral
              titleFieldName={s('Campaign name')}
              initial={useMemo(() => (poll ? {
                route: poll.route || undefined,
                country: poll.country || undefined,
              } : undefined), [poll])}
            />
          </Step>

          <Step label={s('Target Audience')}>
            {editSource
              ? (
                <StepTarget
                  avail={[Sources.excel, Sources.text, Sources.phonebook]}
                  targetType="poll"
                />
              )
              : (
                <StepTargetReadOnly
                  campaignId={+pollId}
                  onEdit={() => setEditSource(true)}
                />
              )}
          </Step>

          <Step label={s('Settings')}>
            {editSource
              ? (
                <StepSettings
                  sourceType={form.getValue(FormField.sourceType)}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )
              : (
                <StepSettingsReadOnly
                  sourceType={Sources.campaign}
                  sourceValue={form.getValue(FormField.sourceValue) || null}
                />
              )}
          </Step>

          <Step label={s('Questions')}>
            <StepQuestions
              sourceType={form.getValue(FormField.sourceType)}
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={poll?.conversationTemplateId?.id || undefined}
            />
          </Step>

          <Step label={scheduleTimes ? s('Schedule') : <SchedulerLabel value={schedulerOn} onChange={setSchedulerOn} />}>
            {schedulerOn
              ? <StepScheduler
                  mask={scheduleTimes}
                  initialvalue={form.getValue(FormField.scheduleTimes) || scheduleTimes}
              />
              : <Typography variant="subtitle1">{s('Campaign will be sent now')}</Typography>}
          </Step>
        </Stepper>
        <ActionsBox>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              variant="outlined"
              onClick={() => navigate(-1)}
            >{s('Cancel')}</Button>
            <Button
              onClick={onDraft || (() => navigate('/campaign'))}
              variant="outlined"
              disabled={!hasChanges}
            >{s('Save')}</Button>
            <Button
              type="submit"
              variant="contained"
            >{s('Create new poll')}</Button>
          </Stack>
        </ActionsBox>
      </FormProvider>
    </Page>
  );
}

