import { s } from 'i18n';
import Scheduler from 'shared-scope/components/Scheduler';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormController } from 'shared-scope/components/Form';
import { FormField } from '../types';
import { DEFAULTS } from '../helpers/const';

type StepSchedulerProps = {
  initialvalue?: string | null | undefined
  mask?: string | null | undefined
};

export default function StepScheduler({
  initialvalue,
  mask,
}: StepSchedulerProps) {
  return (
    <Stack spacing={3}>
      <Typography variant="subtitle1">{s('Timezone of message recipient will be used')}</Typography>
      <FormController
        name={FormField.scheduleTimes}
        initial={initialvalue || mask || DEFAULTS.schedule_times}
        render={({ value, onChange }) => (
          <Scheduler value={value} onChange={onChange} mask={mask} />
        )}
      />
    </Stack>
  );
}

export type StepSchedulerRow = {
  schedule_times?: string
};
