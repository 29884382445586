import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { s } from 'i18n';
import { Step, Stepper } from 'shared-scope/components/Stepper';
import Page from 'layout/Page';
import { fetcher } from 'graphql-api/fetcher';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
 Actions, StepSettings, StepSettingsRow, StepTarget, StepTargetRow,
} from 'products/shared/Campaign/steps';
import { FormField, request, Sources } from 'products/shared/Campaign';
import { Typography } from '@mui/material';
import { useRequest } from '@xeebi/neru';
import { omit } from 'lodash';
import { GetPhoneBookQuery, getSdk } from 'products/common/queries.generated';

const api = getSdk(fetcher);

export default function ImportToPhoneBook() {
  const form = useForm<ImportPhoneBookRow>();
  const navigate = useNavigate();
  const { addSuccess, addError } = useAlert();
  const { bookId = 0 } = useParams();
  const [back, setBack] = useState('/phone-book');

  const {
    isLoading: isLoadingImport,
    error: errorImport,
    fetch: importContacts,
    result: resultImport,
  } = useRequest(request.importContacts);

  const {
    fetch: fetchPhoneBook,
    result: phonebooks,
    isLoading: isLoadingPhonebook,
    error: errorPhonebook,
  } = useRequest<GetPhoneBookQuery>(api.getPhoneBook);

  const phonebook = useMemo(
    () => (phonebooks?.phoneBook?.length ? phonebooks?.phoneBook[0] : {}),
    [phonebooks?.phoneBook],
  );

  useEffect(() => {
    if (phonebook?.id) {
      setBack(`/phone-book/${phonebook?.folder?.id || null}/${phonebook?.id || null}`);
    }
  }, [phonebook]);

  if (phonebooks?.phoneBook?.length === 0) {
    addError(s('Phone book not found'));
    navigate(back);
  }

  const onSubmit = useCallback(async (row: ImportPhoneBookRow) => {
    if (!phonebook) {
      return;
    }

    const data = {
      ...omit(row.source_value?.data, ['data_lines', 'data_rowset', 'data_sheets', 'file']),
      ...omit(row.source_value, ['data']),
      phone_book_id: phonebook.id,
      phone_book_folder_id: phonebook.folder?.id,
      source_type: row.source_type,
      mapping: row.mapping,
      skip: row.mapping_param?.skipTopRows,
      phone_add: row.mapping_param?.addPrefix,
      phone_cut: row.mapping_param?.cutDigits,
      delimiter: row.mapping_param?.delimiter,
      enclosure: row.mapping_param?.quoteCharacter,
      sheets: row.mapping_param?.sheet,
      guess_state: true,
      guess_timezone: true,
      guess_gender: true,
    };

    await importContacts(data);
  }, [phonebook, importContacts]);

  useEffect(() => {
    fetchPhoneBook({
      filter: JSON.stringify({ id: +bookId }),
    });
  }, [fetchPhoneBook, bookId]);

  useEffect(() => {
    errorPhonebook && addError(errorPhonebook.getMessage());
    errorImport && addError(errorImport.getMessage());
    errorPhonebook && navigate(back);
  }, [errorPhonebook, errorImport, addError, navigate, back]);

  useEffect(() => {
    resultImport && addSuccess(s('New contacts are imported'));
    resultImport && navigate(back);
  }, [resultImport, addSuccess, navigate, back]);

  return (
    <Page title={s('Import contacts into phone book')} back={back} loading={isLoadingPhonebook || isLoadingImport}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <Typography variant="h4">{s('Phone book name: :name', { name: phonebook?.name })}</Typography>
          </Step>
          <Step label={s('Source')}>
            <StepTarget avail={[Sources.excel, Sources.text]} targetType="phone-book" />
          </Step>
          <Step label={s('Settings')}>
            <StepSettings
              sourceType={form.getValue(FormField.sourceType)}
              sourceValue={form.getValue(FormField.sourceValue) || null}
            />
          </Step>
        </Stepper>
        <Actions
          submitText={s('Import contacts')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}

type ImportPhoneBookRow = StepTargetRow & StepSettingsRow;
