import React, {
  MutableRefObject,
} from 'react';
import {
  GridColDef,
  GridHeaderFilterCellProps,
} from '@mui/x-data-grid-pro';
import {
  Box,
  Typography,
  Stack,
} from '@mui/material';
import {
  CampaignETA,
  GridType,
} from 'products/CampaignList/types';
import { s } from 'i18n';
import styles from 'products/CampaignList/styles.module.scss';
import { CampaignStatus, ConversationTemplate } from 'src/graphql-api';
import { Action } from 'products/CampaignList/components/Action';
import { StatusBadge } from 'products/CampaignList/components/StatusBadge';
import { FilterDropdown, FilterDateRange, GridRefType } from '@xeebi/neru';
import DateTime from 'shared-scope/components/DateTime';

export const DRIP = s('Drip');
export const REGULAR = s('Regular');
export const ARCHIVE = s('Archive');

type GetStructProps = {
  campaignStatuses: CampaignStatus[]
  templates: ConversationTemplate[]
  gridRef: MutableRefObject<GridRefType>
  gridType: GridType
  hasActionPermission: boolean
  tab: String | null
};

export default function Struct({
  campaignStatuses,
  templates,
  gridRef,
  gridType,
  hasActionPermission,
  tab,
}: GetStructProps) {
  const statusCol: GridColDef[] = (tab === ARCHIVE && tab)
    ? []
    : [
      {
        field: 'status',
        headerName: s('Status'),
        disableColumnMenu: true,
        type: 'custom',
        renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
          <FilterDropdown
            {...params}
            options={campaignStatuses}
            keyName="id"
            valueName="name"
            placeholder={s('Status')}
            minWidth={150}
          />
        ),
        renderCell: ({ row, value }) => (
          <StatusBadge row={row} value={value} gridType={gridType} />
        ),
        minWidth: 200,
        flex: 0,
      },
    ];

  const columns: GridColDef[] = ([] as GridColDef[]).concat(
    !hasActionPermission
      ? []
      : [
        {
          field: 'action',
          headerName: 'Action',
          renderCell: ({ row }) => (
            <Action
              row={row}
              onAction={(data: CampaignETA) => {
                gridRef.current.updateRows([data]);
                gridRef.current.refresh();
              }}
            />
          ),
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          minWidth: 100,
          flex: 0,
        },
      ],
    [
      {
        field: 'id',
        headerName: 'ID',
        type: 'number',
        minWidth: 100,
        disableColumnMenu: true,
        filterable: true,
        flex: 0,
      },
      {
        field: 'name',
        type: 'string',
        headerName: s('Name::2'),
        disableColumnMenu: true,
        filterable: true,
        editable: false,
        minWidth: 250,
        flex: 1,
      },

      ...statusCol,
    ],

    gridType === GridType.Campaign ? [] : [
      {
        field: 'conversationStartReplyRate',
        headerName: 'Completion rate',
        type: 'number',
        minWidth: 90,
        disableColumnMenu: true,
        flex: 0,
        renderCell: ({ row }) => {
          return (
            <div className="text-nowrap">
              <div>
                <b>{row.conversationStartReplyRate ? (row.conversationStartReplyRate).toFixed(1) : 0}%</b>
              </div>
              <div className={styles.campaignDateTime}>
                {row.conversationStartReplied ? row.conversationStartReplied : 0}
              </div>
            </div>
          );
        },
        filterable: false,
      },
      {
        field: 'conversationWithAttention',
        headerName: 'Attention',
        type: 'number',
        minWidth: 100,
        disableColumnMenu: true,
        filterable: false,
        flex: 0,
        renderCell: ({ value }) => {
          return (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              sx={{ height: '100%' }}
            >
              {value
                ? <Box className={styles.attentionSign} />
                : null}
              <Typography>{value}</Typography>
            </Stack>
          );
        },
      },
    ],

    gridType === GridType.AgentPoll ? [] : [
      {
        field: 'conversationTemplateId',
        headerName: s('Template'),
        disableColumnMenu: true,
        type: 'custom',
        renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
          <FilterDropdown
            {...params}
            options={templates}
            keyName="id"
            valueName="name"
            placeholder={s('Template')}
            minWidth={150}
          />
        ),
        valueFormatter: (value: ConversationTemplate) => {
          return value?.name || '';
        },
        minWidth: 200,
        flex: 1,
      },
    ],

    [
      {
        field: 'startTs',
        headerName: s('Start date'),
        disableColumnMenu: true,
        type: 'custom',
        renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
          <FilterDateRange
            {...params}
            placeholder={s('Start date')}
          />
        ),
        valueGetter: (value) => (value ? new Date(value) : null),
        renderCell: ({ value }) => <DateTime value={value} direction="column" />,
        minWidth: 150,
        flex: 0,
      },
      {
        field: 'stopTs',
        headerName: s('Stop date'),
        disableColumnMenu: true,
        type: 'custom',
        renderHeaderFilter: (params: GridHeaderFilterCellProps) => (
          <FilterDateRange
            {...params}
            placeholder={s('Stop date')}
          />
        ),
        valueGetter: (value) => (value ? new Date(value) : null),
        renderCell: ({ value }) => <DateTime value={value} direction="column" />,
        flex: 0,
        minWidth: 150,
      },
    ],

  );
  return columns;
}
