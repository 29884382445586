import * as CONFIG from 'shared-scope/config';
import {
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { s } from 'i18n';
import axios from 'axios';

import React, {
  useState,
  useCallback, useMemo,
} from 'react';
import { useMountedState } from '@xeebi/neru';
import { AttachmentSize } from 'products/common/types';
import { isUndefined } from 'lodash';
import useAlert from 'shared-scope/hooks/useAlert';
import useAttachments from 'shared-scope/hooks/useAttachments';
import Preview from './Preview';
import AttachDialog from './AttachDialog';

export default function AttachmentPreview({
  attachment,
  onChange,
  maxFileSize,
  error,
}: AttachmentPreviewProps) {
  const [showAttachmentList, setShowAttachmentList] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const isMounted = useMountedState();
  const { addError } = useAlert();
  const { attachmentList, refreshAttachments } = useAttachments();

  const checkSize = !isUndefined(maxFileSize);

  const fileName = useMemo(
    () => attachmentList.find((att) => att.id === attachment)?.name || '',
    [attachmentList, attachment],
  );

  const uploadFile = useCallback(async (file: File) => {
    setLoadingFile(true);
    try {
      const data = new FormData();
      data.append('attachment_id', file);
      const { status, data: uploadData } = await axios.post(
        `${CONFIG.get('api')}/attachment`,
        data,
      );
      if (status === 200) {
        await refreshAttachments();
      } else {
        console.warn(uploadData);
      }
    } catch (e: any) {
      console.error(e);
      addError(e.response?.data?.error || s('Upload error'));
    } finally {
      isMounted() && setLoadingFile(false);
    }
  }, [isMounted, addError, refreshAttachments]);

  const deleteFile = useCallback(async (attachment_id) => {
    setLoadingList(true);
    try {
      const { status } = await axios.delete(`${CONFIG.get('api')}/attachment/${attachment_id}`);
      if (status === 200) {
        await refreshAttachments();
      } else {
        console.warn(`Can't delete file ${attachment_id}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      isMounted() && setLoadingList(false);
    }
  }, [isMounted, refreshAttachments]);

  return (
    <Stack>
      {
        attachment ? (
          <Preview
            attachment={attachment}
            onChange={(v) => onChange && onChange(v)}
            fileName={fileName}
            error={error}
          />
        ) : (
          <IconButton
            aria-label="Attach file"
            color="primary"
            sx={{ borderRadius: '28px' }}
            onClick={() => setShowAttachmentList(true)}
            disabled={checkSize && !maxFileSize}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2"><i className="icon-paperclip" /></Typography>
              <Typography variant="body2">{s('Attach file...')}</Typography>
            </Stack>
          </IconButton>
        )
      }

      {!checkSize || maxFileSize
        ? <AttachDialog
            open={showAttachmentList}
            attachmentList={attachmentList}
            selected={attachment || null}
            onSelect={(att) => {
              setShowAttachmentList(false);
              onChange && onChange(att);
            }}
            onDelete={(id) => deleteFile(id)}
            onUpload={(file) => uploadFile(file)}
            onClose={() => setShowAttachmentList(false)}
            loadingList={loadingList}
            loadingFile={loadingFile}
            maxFileSize={maxFileSize}
        />
        : null}
    </Stack>
  );
}

type AttachmentPreviewProps = {
  attachment: string | null | undefined
  onChange?: ((attachment: string | null) => void) | undefined
  maxFileSize?: AttachmentSize
  error?: string
};
