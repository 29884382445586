import axios, { AxiosRequestConfig } from 'axios';
import * as CONFIG from 'shared-scope/config';
import { cloneDeep, forOwn } from 'lodash';
import { Campaign } from 'graphql-api';
import { NewCampaignRow, NewPollRow } from 'products/shared/Campaign/steps/types';


const ACTION_CHAT_BOT = 6;

export const createCampaign = async (
  data: Record<string, any>,
  config?: AxiosRequestConfig,
) => axios.post(
  `${CONFIG.get('api')}/campaign/import`,
  data,
  config,
);

export const importContacts = async (
  data: Record<string, any>,
  config?: AxiosRequestConfig,
) => {
  if (!data.phone_book_id) {
    throw new Error('Incorrect Phone book');
  }
  return axios.post(
    `${CONFIG.get('api')}/phone-book/${data.phone_book_id}/import`,
    data,
    config,
  );
};

export function uploadSource(data: UploadData, config?: object) {
  const formData = new FormData();
  forOwn(data, (v, k) => v && formData.append(k, v));
  return axios.post(
    `${CONFIG.get('api')}/import/upload`,
    formData,
    config || {},
  );
}

export const updateCampaign = async ({ campaign, schedulerOn, newCampaign }: UpdateCampaign, config?: AxiosRequestConfig) => {
  const params = {
    campaign_name: newCampaign.title,
    schedule_enabled: schedulerOn,
    schedule_times: newCampaign.schedule_times,
    params: campaign.params,
    mobius_notify: true,
    message_templates: [{ text: newCampaign.message?.text, enabled: true }],
    campaign_status: campaign?.status?.id,
  };
  return axios.put(`${CONFIG.get('api')}/campaign/${campaign.id}`, params, config);
};

export const updatePoll = async ({ poll, schedulerOn, newPoll }: UpdatePoll, config?: AxiosRequestConfig) => {
  const conversationTemplate = cloneDeep(newPoll.questions?.template);
  if (conversationTemplate) {
    conversationTemplate.final_attachment_id = newPoll.route?.maxAttachmentSize ? conversationTemplate?.final_attachment_id : null;
    delete conversationTemplate?.final_error;
    conversationTemplate.questions = conversationTemplate.questions?.map((q) => {
      const attId = newPoll.route?.maxAttachmentSize ? q.attachment_id : null;
      delete q.error;
      return { ...q, ...{ attachment_id: attId } };
    }) || [];
  }
  const params = {
    campaign_name: newPoll.title,
    schedule_enabled: schedulerOn,
    schedule_times: newPoll.schedule_times,
    params: {
      ...poll.params,
      reply_actions: [{
        keywords: newPoll.questions?.initialAnswer || '',
        action_type: ACTION_CHAT_BOT,
        message_template: null,
      }],
    },
    mobius_notify: true,
    message_templates: [{
      text: newPoll.questions?.initialMessage,
      enabled: true,
    }],
    campaign_status: poll?.status?.id,
    conversation_template: conversationTemplate,
    conversation_template_id: newPoll.template?.id || null,
  };
  return axios.put(`${CONFIG.get('api')}/campaign/${poll.id}`, params, config);
};

export const updateCampaignStatus = async ({ campaignId, status }: UpdateCampaignStatus, config?: AxiosRequestConfig) => {
  return axios.put(`${CONFIG.get('api')}/campaign/${campaignId}`, {
    campaign_status: status,
  }, config);
};

type UpdateCampaignStatus = {
  campaignId: number
  status: number
};

type UpdateCampaign = {
  campaign: Campaign
  newCampaign: NewCampaignRow
  schedulerOn: boolean
};

type UpdatePoll = {
  poll: Campaign
  newPoll: NewPollRow
  schedulerOn: boolean
};

type UploadData = {
  source_type: string
  text?: string
  file?: File
  phone_book_id?: string
  phone_book_filter?: string
  campaign_id?: string
  campaign_filter?: string
};
