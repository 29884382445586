import React, { useState, useEffect } from 'react';
import Page from 'layout/Page';
import { s } from 'i18n';
import { Stepper, Step } from 'shared-scope/components/Stepper';
import {
  Box, Button, Stack, TextField, ToggleButtonGroup,
  ToggleButton, Typography, Icon, Alert, Grid,
} from '@mui/material';
import * as CONFIG from 'shared-scope/config';
import Loading from 'shared-scope/components/Loading';
import axios from 'axios';
import {
  PermissionWrapper,
  PERMISSION_GENERAL,
} from 'permission';
import profile from 'media/images/profile.svg';
import {
 Users, Prices, TermsConditions, API as ApiTab,
} from './Tabs';
import Billing from './Tabs/Billing';
import style from './account.module.scss';


async function getAccount() {
  const { data } = await axios.get(
    `${CONFIG.get('api')}/account`,
  );
  return data;
}

async function updateAvatar(file: File) {
  const { data }: any = await axios.post(
    `${CONFIG.get('api')}/account/avatar`,
    {
      avatar: file,
      _method: 'PUT',
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return data.avatar;
}

async function updateAccount(params: any) {
  const { data } = await axios.put(`${CONFIG.get('api')}/account/update`, params);
  return data;
}

const GENERAL = s('General information');
const USER_ROLES = s('User Roles');
const TERMS = s('Terms and conditions');
const PRICES = s('Pricing');
const API = s('API');
const BILLING = s('Billing');

export default function Account() {
  const [account, setAccount] = useState<null | any>(null);
  const [ava, setAva] = useState<null | string>(null);
  const [file, setFile] = useState<null | File>(null);
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [company, setCompany] = useState('');
  const [username, setUsername] = useState('');
  const [changeAccount, setChangeAccount] = useState(false);

  const [tab, setTab] = useState(GENERAL);

  const passwordEqual = password === confirmPassword;
  const isValid = !!(confirmPassword && passwordEqual);
  const available = (company !== account?.account?.customer_name) || (username && username !== account?.user_name);

  useEffect(() => {
    let active = true;
    const getData = async () => {
      try {
        setLoading(true);
        const data = await getAccount();

        if (active) {
          setAccount(data);
          setAva(data?.avatar || null);
          setCompany(data?.account?.customer_name || '');
          setUsername(data?.user_name || '');
        }
      } catch (e) {
        console.error(e);
      } finally {
        active && setLoading(false);
      }
    };

    getData();

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    let active = true;
    const upload = async () => {
      try {
        if (!file) {
          return;
        }
        setLoading(true);
        const newAvatar = await updateAvatar(file);
        active && setAva(newAvatar);
      } catch (e) {
        console.error(e);
      } finally {
        active && setLoading(false);
        active && setFile(null);
      }
    };

    upload();

    return () => {
      active = false;
    };
  }, [file]);

  useEffect(() => {
    let active = true;
    const update = async () => {
      try {
        setLoading(true);
        const data = await updateAccount({
          ...account,
          new_password: password,
          new_password_confirmation: confirmPassword,
          current_password: currentPassword,
        });
        if (active) {
          setAccount(data);
          setPassword('');
          setConfirmPassword('');
          setCurrentPassword('');
          setMessage(s('The password has been successfully changed'));
        }
      } catch (e: any) {
        if (e?.response?.data?.errors) {
          const errorList: string[] = [];
          (Object.values(e?.response?.data?.errors) as string[][]).map((errors) => errors.map((err) => errorList.push(err)));
          if (errorList.length > 0) {
            active && setError(errorList.join('\n'));
          }
        }
        console.error(e);
      } finally {
        active && setLoading(false);
        active && setChangePassword(false);
      }
    };

    changePassword && update();

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [changePassword]);

  useEffect(() => {
    let active = true;
    const update = async () => {
      try {
        setLoading(true);
        const newAccount = { ...account };
        newAccount.user_name = username;
        newAccount.account.customer_name = company;

        const data = await updateAccount(newAccount);

        if (active) {
          setAccount(data);
          setMessage(s('Account is updated successfully'));
        }
      } catch (e: any) {
        if (e?.response?.data?.errors) {
          const errorList: string[] = [];
          (Object.values(e?.response?.data?.errors) as string[][]).map((errors) => errors.map((err) => errorList.push(err)));
          if (errorList.length > 0) {
            active && setError(errorList.join('\n'));
          }
        }
        console.error(e);
      } finally {
        active && setLoading(false);
        active && setChangeAccount(false);
      }
    };

    changeAccount && update();

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [changeAccount]);

  return (
    <Page title={s('Account settings')}>
      {error && <Alert sx={{ marginBottom: 2 }} severity="error" onClose={() => setError('')}>{error}</Alert>}
      {message && <Alert sx={{ marginBottom: 2 }} severity="success" onClose={() => setMessage('')}>{message}</Alert>}
      <Grid container direction="row" spacing={8}>
        <Grid item md={2}>
          <Stack spacing={2}>
            {loading
              ? <Loading logo={false} size={180} />
              : null}
            {!loading
              ? (
                <Button
                  className={style.avatar}
                  component="label"
                  color="inherit"
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    borderRadius: '50%',
                    '&:hover .MuiStack-root': {
                      opacity: '1',
                    },
                  }}
                >
                  <input
                    type="file"
                    accept="image/jpeg,image/png"
                    className="d-none"
                    onChange={(e) => setFile(e.target?.files?.[0] || null)}
                  />
                  <Stack
                    sx={{
                      position: 'absolute',
                      opacity: '0',
                      color: 'white',
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Icon className="material-icons" fontSize="large">photo_camera</Icon>
                    <Typography>{s('Change photo')}</Typography>
                  </Stack>
                  {ava
                    ? <img alt="ava" src={ava} />
                    : <img alt="profile" src={profile} />}
                </Button>
              )
              : null}
            <Typography variant="h5">{s(`Hi, ${username}`)}</Typography>
          </Stack>
        </Grid>
        <Grid item md={10}>
          <Stack spacing={4}>
            <PermissionWrapper scopes={[PERMISSION_GENERAL]}>
              <ToggleButtonGroup
                exclusive
                size="small"
                aria-label="settings"
                orientation="horizontal"
                value={tab}
                onChange={(e, v) => (v !== null) && setTab(v)}
              >
                <ToggleButton key={GENERAL} value={GENERAL}>{GENERAL}</ToggleButton>
                <ToggleButton key={USER_ROLES} value={USER_ROLES}>{USER_ROLES}</ToggleButton>
                <ToggleButton key={PRICES} value={PRICES}>{PRICES}</ToggleButton>
                <ToggleButton key={BILLING} value={BILLING}>{BILLING}</ToggleButton>
                <ToggleButton key={TERMS} value={TERMS}>{TERMS}</ToggleButton>
                <ToggleButton key={API} value={API}>{API}</ToggleButton>
              </ToggleButtonGroup>
            </PermissionWrapper>
            {tab === GENERAL && (
              <Stepper>
                <Step
                  label={s('Profile')}
                  icon={<Icon className="icon-account" fontSize="medium" />}
                >
                  <Stack spacing={4}>
                    <TextField
                      label={s('Company')}
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      variant="outlined"
                      sx={{ width: '600px' }}
                    />
                    <TextField
                      label={s('Username')}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      variant="outlined"
                      sx={{ width: '600px' }}
                      required
                    />
                    <TextField
                      label={s('E-mail')}
                      type="email"
                      value={account?.email || ''}
                      variant="outlined"
                      sx={{ width: '600px' }}
                      disabled
                      required
                    />
                    <Box>
                      <Button
                        variant="contained"
                        size="medium"
                        disabled={!available}
                        onClick={() => setChangeAccount(true)}
                      >
                        {s('Save changes')}
                      </Button>
                    </Box>
                  </Stack>
                </Step>
                <Step
                  label={s('Change password')}
                  icon={<Icon className="material-icons" fontSize="medium">security</Icon>}
                >
                  <Stack spacing={4}>
                    <TextField
                      label={s('New password')}
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      variant="outlined"
                      sx={{ width: '600px' }}
                      error={!!password && !isValid}
                      required
                    />
                    <TextField
                      label={s('Confirm new password')}
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      variant="outlined"
                      sx={{ width: '600px' }}
                      helperText={passwordEqual ? '' : <Typography component="span" color="error">{s('The password confirmation does not match.')}</Typography>}
                      error={!!confirmPassword && !isValid}
                      required
                    />
                    {isValid
                      ? (
                        <TextField
                          label={s('Current password')}
                          type="password"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          variant="outlined"
                          sx={{ width: '600px' }}
                          required
                        />
                      )
                      : null}
                    <Box>
                      <Button
                        variant="contained"
                        size="medium"
                        disabled={!(confirmPassword && confirmPassword === password)}
                        onClick={() => setChangePassword(true)}
                      >
                        {s('Change password')}
                      </Button>
                    </Box>
                  </Stack>
                </Step>
              </Stepper>
            )}
            {tab === USER_ROLES && (
              <Users />
            )}
            {tab === PRICES && (
              <Prices />
            )}
            {tab === BILLING && (
              <Billing />
            )}
            {tab === TERMS && (
              <TermsConditions />
            )}
            {tab === API && (
              <ApiTab />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
}
