import React, {
  useEffect,
  useState,
} from 'react';
import { QuestionType } from 'products/common/types';
import { roundRobin } from 'shared-scope/helpers/functions';
import { MessageType } from '../types';
import Phone from './Phone';
import { createQuestion, createAnswer } from '../helpers';

export default function PhonePreview({
  initialMessage,
  initialAnswer,
  initialAttachment,
  finalMessage,
  finalAttachment,
  questions,
  height = 600,
}: PhonePreviewProps) {
  const [chat, setChat] = useState<MessageType[]>([]);

  useEffect(() => {
    const chatExample: MessageType[] = [];
    if (initialMessage) {
      chatExample.push(createQuestion(initialMessage, initialAttachment));
    }
    if (initialAnswer) {
      chatExample.push(createAnswer(initialAnswer));
    }
    if (questions.length > 0) {
      for (const q of questions) {
        if (q.question) {
          chatExample.push(createQuestion(q.question, q.attachment_id));
          chatExample.push(createAnswer(answerGenerator.next().value));
        }
      }
    }
    if (finalMessage) {
      chatExample.push(
        createQuestion(
          finalMessage,
          finalAttachment,
        ),
      );
    }
    setChat(chatExample.map((m, i) => {
      m.position = i + 1;
      return m;
    }));
  }, [initialMessage, initialAnswer, initialAttachment, questions, finalMessage, finalAttachment]);
  return (
    <Phone chat={chat} height={height} />
  );
}

const RANDOM_ANSWER = [
  'What?',
  'Yes',
  'No',
  'I don`t known',
  'Maybe',
  'Well of course',
  'Bye',
  'Good',
];

const answerGenerator = roundRobin(RANDOM_ANSWER);

type PhonePreviewProps = {
  initialMessage: string
  initialAnswer: string | null
  initialAttachment: string | null
  finalMessage: string | null
  finalAttachment: string | null
  questions: QuestionType[]
  height?: number
};
