import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './ext.d';
import { Alert, CssBaseline } from '@mui/material';
import * as CONFIG from 'shared-scope/config';
import './main.scss';
import { LicenseInfo } from '@mui/x-license-pro';
import { BrandFiles, getBrandImg } from 'shared-scope/helpers/functions';
import App from './App';
import Theme from './Theme';

LicenseInfo.setLicenseKey('a34d2c0369561b15a884d6dbdd3ff1aaTz03ODI1NyxFPTE3MzEwNTA4ODgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function getEffectiveUser() {
  let EUID = null;
  let CID = null;
  const re = new RegExp(`^\\/([0-9]+\\/*)?${process.env.REACT_APP_BASE_PATH}\\/?([0-9]+)?`);
  const m = window.location.pathname.match(re);

  if (m && m[1]) CID = parseInt(m[1], 10);
  if (m && m[2]) EUID = parseInt(m[2], 10);
  return [CID, EUID];
}

function getBasePath() {
    const [CID, EUID] = getEffectiveUser();
    let BASENAME = `${CID ? `/${CID}` : ''}` + PUBLIC_PATH.replace(/(\/login)?\/$/, '/') + `${EUID ? `${EUID}/` : ''}`;
    BASENAME = BASENAME.replace(/\/$/, '');
    return BASENAME;
}

/**
 * Get config
 */
function init() {
    const [CID, EUID] = getEffectiveUser();
    const basePath = getBasePath();
    window.BASENAME = basePath;

    const configPath = `${basePath}${process.env.REACT_APP_CONFIG_PATH || '/config.json'}?${Math.random()}`;

    console.log('path to config file: ', configPath);
    return axios.get(configPath)
        .then(({ data }) => {
            if (!data) {
                throw new Error('Cannot find config.json');
            } else {
                CONFIG.save({
                    ...data,
                    EUID,
                    CID,
                });
            }
        });
}

Promise.all([init()])
  .then(() => {
    /**
     * Set App name and Description
     */
    document.title = CONFIG.get('app_name', 'SMS campaign sender');
    document.querySelector('meta[name="description"]')
      ?.setAttribute('content', CONFIG.get('app_description', 'SMS campaign sender'));

    /**
     * Set favicon
     */
    let linkFavicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!linkFavicon) {
      linkFavicon = document.createElement('link');
      linkFavicon.rel = 'icon';
      document.head.appendChild(linkFavicon);
    }
    linkFavicon.href = getBrandImg(BrandFiles.Favicon);

    /**
     * Set Apple touch icon
     */
    let linkAppleTouch = document.querySelector("link[rel~='apple-touch-icon']") as HTMLLinkElement;
    if (!linkAppleTouch) {
      linkAppleTouch = document.createElement('link');
      linkAppleTouch.rel = 'apple-touch-icon';
      document.head.appendChild(linkAppleTouch);
    }
    linkAppleTouch.href = getBrandImg(BrandFiles.Logo192);
  })
    .then(() => {
        ReactDOM.render(
          <React.StrictMode>
            <Theme>
              <CssBaseline />
              <App />
            </Theme>
          </React.StrictMode>,
          document.getElementById('root'),
        );
    })
    .catch((err) => {
        console.error('Start app error: ', err);
        // Render error
        ReactDOM.render(
          <Alert severity="error">
            {(err.response && err.response.data && err.response.data.message)
              ? err.response.data.message : err.message}
          </Alert>,
         document.getElementById('root'),
        );
    });


