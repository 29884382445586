import { FormProvider, useForm } from 'shared-scope/components/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import useAlert from 'shared-scope/hooks/useAlert';
import { s } from 'i18n';
import Page from 'layout/Page';
import { useRequest } from '@xeebi/neru';
import React, { useCallback, useEffect } from 'react';
import { Stepper, Step } from 'shared-scope/components/Stepper';
import {
  DEFAULTS, FormField, request, Sources,
} from 'products/shared/Campaign';
import {
  Actions, StepGeneral, StepGeneralRow, StepOptInMessage, StepOptInMessageRow, StepTarget, StepTargetRow,
} from 'products/shared/Campaign/steps';
import { SOURCE_PHONE_BOOK } from 'products/CampaignList/helpers';
import { CAMPAIGN_TYPE_OPT_IN } from 'products/common/helpers';
import { ACTION_CHAT_BOT } from 'products/Poll/helpers';
import { cloneDeep } from 'lodash';
import { SourcePhoneBookValue } from 'products/shared/Campaign/types';
import { CountryCapability } from 'graphql-api';
import { QuestionType } from 'products/common/types';

const back = '/opt-in';

export default function NewOptIn() {
  const form = useForm<NewOptInRow>();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    addSuccess,
    addError,
  } = useAlert();
  const {
    isLoading, error, fetch: createOptIn, result,
  } = useRequest(request.createCampaign);

  const templateId = new URLSearchParams(location.search).get('templateId');

  const onSubmit = useCallback(async (row: NewOptInRow) => {
    const conversationTemplate = cloneDeep(row.questions?.template);
    if (conversationTemplate) {
      conversationTemplate.final_attachment_id = row.route?.maxAttachmentSize ? conversationTemplate?.final_attachment_id : null;
      delete conversationTemplate?.final_error;
      conversationTemplate.questions = conversationTemplate.questions?.map((q: QuestionType) => {
        const attId = row.route?.maxAttachmentSize ? q.attachment_id : null;
        delete q.error;
        return { ...q, ...{ attachment_id: attId } };
      }) || [];
    }

    const data = {
      ...DEFAULTS,
      ...{
        campaign_name: row.title,
        country_id: row.route?.country?.id,
        connection_id: row.route?.route?.id,
        source_type: SOURCE_PHONE_BOOK,
        type: CAMPAIGN_TYPE_OPT_IN,
        reply_actions: [{
          keywords: row.questions?.initialAnswer || '',
          action_type: ACTION_CHAT_BOT,
          message_template: null,
        }],
        conversation_template: conversationTemplate,
        conversation_template_id: row.template?.id,
        address_book_id: (row.source_value as SourcePhoneBookValue)?.phone_book_id,
        src_address: row?.number?.number || null,
        src_address_id: row?.number?.number_id || null,
      },
    };
    await createOptIn(data);
  }, [createOptIn]);

  useEffect(() => {
    error && addError(s('Error creating a new opt-in campaign'));
  }, [error, addError]);

  useEffect(() => {
    result && addSuccess(s('New opt-in campaign added successfully'));
    result && navigate(back);
  }, [result, addSuccess, navigate]);

  return (
    <Page title={s('Create opt-in campaign')} back={back} loading={isLoading}>
      <FormProvider form={form} onSubmit={onSubmit}>
        <Stepper>
          <Step label={s('General')}>
            <StepGeneral titleFieldName={s('Campaign name')} />
          </Step>
          <Step label={s('Message flow')}>
            <StepOptInMessage
              maxAttachmentSize={(form.getValue(FormField.route) as CountryCapability)?.maxAttachmentSize || 0}
              templateId={templateId ? +templateId : null}
            />
          </Step>
          <Step label={s('Target')}>
            <StepTarget avail={[Sources.phonebook]} targetType="opt-in" noValidate />
          </Step>
        </Stepper>
        <Actions
          submitText={s('Create')}
          onCancel={() => navigate(back)}
        />
      </FormProvider>
    </Page>
  );
}

type NewOptInRow = StepGeneralRow & StepTargetRow & StepOptInMessageRow;
