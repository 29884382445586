export type MappingData = {
  file_type: 'csv' | 'excel'
  sheets?: string[]
  data_lines?: string
  data_sheets?: any[]
  data_rowset?: any[]
  data_count?: number
  delimiter?: string
  mapping: Mapping
};

export type Mapping = { [key: string]: number };
export type MappingTitle = { [key: string]: string };

export enum ImportStatus {
  Queued = 0,
  Importing = 1,
  Complete = 2,
  Error = 3,
  Cancelled = 4,
}

export enum ImportType {
  PhoneBook = 0,
  Campaign = 1,
  StopList = 2,
  ShortLink = 3,
  NumberLookup = 4,
}

export type QuestionType = {
  question: string
  key?: string
  position?: number
  attachment_id: string | null
  validator: ValidatorType
  field_name?: string
  answer_type: AnswerType
  error?: QuestionError
  keywords?: string[]
};

export type QuestionsTemplate = {
  id?: number
  name?: string
  initialMessage: string
  initialAnswer: string
  initialAttachment: { id: string | null }
  initialError?: QuestionError
  template: {
    questions?: QuestionType[]
    final_message?: string | null
    final_attachment_id?: string | null
    final_error?: QuestionError
  }
};

export type QuestionError = {
  question?: string
  reply?: string
  attachment?: string
};

export enum AnswerType {
  Text = 'text',
  OptIn = 'opt_in',
}

export enum ValidatorType {
  AnyText = 'Any text',
}

export enum MappingColumns {
  COLUMN_ID = 'address_id',
  COLUMN_PHONE = 'phone_number',
  COLUMN_FIRST_NAME = 'first_name',
  COLUMN_LAST_NAME = 'last_name',
  COLUMN_GENDER = 'gender',
  COLUMN_STATE = 'state',
  COLUMN_COUNTRY = 'country',
  COLUMN_TIMEZONE = 'tz',
  COLUMN_CUSTOM_1 = 'custom_1',
  COLUMN_CUSTOM_2 = 'custom_2',
  COLUMN_CUSTOM_3 = 'custom_3',
  COLUMN_CUSTOM_4 = 'custom_4',
  COLUMN_CUSTOM_5 = 'custom_5',
  COLUMN_CUSTOM_6 = 'custom_6',
  COLUMN_CUSTOM_7 = 'custom_7',
  COLUMN_CUSTOM_8 = 'custom_8',
  COLUMN_CUSTOM_9 = 'custom_9',
  COLUMN_CUSTOM_10 = 'custom_10',
  COLUMN_CLIENT_MESSAGE_ID = 'client_message_id',
}

export type ContactListFilter = {
  gender?: string | null
  state?: string[] | null
  tz?: string[] | null
  last_campaign_id?: number | null | undefined
};

export type AttachmentSize = number;
