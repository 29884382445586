import {
  ReactNode, useCallback, useEffect, useMemo,
} from 'react';
import { Box } from '@mui/material';
import useFormContext from './useFormContext';
import {
 FormRow, FormRowsErrors, FormValidateRule,
} from './types';

export default function FormController<T = string, R extends FormRow = FormRow>(
  {
    name, render, children, initial, validateRule, className,
  }: FormControllerProps<T, R>) {
  const {
    form: {
      register, unregister, isRegistered, getValue, setValue, getError,
      setValidateRule, clearError, validateFields,
    },
  } = useFormContext<R>();

  const registered = useMemo(() => isRegistered(name), [name, isRegistered]);
  const errors = useMemo(() => getError(name), [name, getError]);
  const value = useMemo(
    () => (registered ? getValue<T>(name) : undefined),
    [registered, getValue, name],
  );

  /**
   * Init new control
   */
  useEffect(() => {
    !registered && register<T>(name, initial);
  }, [registered, name, initial, register]);

  /**
   * Set validation rules
   */
  useEffect(() => {
    validateRule && setValidateRule(name, validateRule as FormValidateRule);
  }, [name, validateRule, setValidateRule]);

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, [name, unregister]);

  const handleChange = useCallback((v: T) => {
      clearError(name);
      setValue<T>(name, v);
    }, [name, setValue, clearError]);

  const handleValidate = useCallback(
    () => {
      clearError(name);
      validateFields(name);
    },
    [name, clearError, validateFields],
  );

  const renderFunc = children ?? render ?? null;

  return <Box className={`${className || ''} formController-${name}`}>
    {typeof renderFunc === 'function'
      ? renderFunc({
        value,
        onChange: handleChange,
        error: errors.length ? errors.map((err) => err.message) : undefined,
        fullError: errors.length ? errors : undefined,
        validate: handleValidate,
      })
      : renderFunc}
  </Box>;
}

type FormControllerProps<T, R extends FormRow = FormRow> = {
  name: string
  initial?: T
  validateRule?: FormValidateRule<R>
  render?: (v: FormControllerRenderProps<T>) => ReactNode | null
  children?: ReactNode | ((v: FormControllerRenderProps<T>) => ReactNode | null) | null
  className?: string
};

type FormControllerRenderProps<T> = {
  value?: T
  onChange: (v: T | null) => void
  error?: string[]
  fullError?: FormRowsErrors
  validate: () => void
};

