import { DEFAULTS, Sources } from 'products/shared/Campaign';
import {
  cloneDeep, get, isEmpty, omit,
} from 'lodash';
import { NewPollRow } from 'products/shared/Campaign/steps';
import { SourcePhoneBookValue } from 'products/shared/Campaign/types';
import { SOURCE_RESPONDER } from 'products/CampaignList/helpers';
import { CAMPAIGN_TYPE_RESPONDER } from 'products/common/helpers';

export const ACTION_CHAT_BOT = 6;

export function getCampaignData(
  row: NewPollRow,
  schedulerOn: boolean,
  isDraft: boolean = false,
  campaignId: number | null = null,
) {
  const isResponder = row.source_type === Sources.responder;
  let phoneBookFilter = get(row?.source_value, 'phone_book_filter', null);
  phoneBookFilter = isEmpty(phoneBookFilter) ? null : phoneBookFilter;

  const conversationTemplate = cloneDeep(row.questions?.template);
  if (conversationTemplate) {
    conversationTemplate.final_attachment_id = row.route?.maxAttachmentSize ? conversationTemplate?.final_attachment_id : null;
    delete conversationTemplate?.final_error;
    conversationTemplate.questions = conversationTemplate.questions?.map((q) => {
      const attId = row.route?.maxAttachmentSize ? q.attachment_id : null;
      delete q.error;
      return { ...q, ...{ attachment_id: attId } };
    }) || [];
  }

  const draft = isDraft ? { is_draft: true } : {};
  const edit = campaignId ? { current_campaign_id: campaignId } : {};

  const data = {
    ...DEFAULTS,
    ...{
      campaign_name: row.title,
      country_id: row.route?.country?.id,
      connection_id: row.route?.route?.id,
      reply_actions: [{
        keywords: row.questions?.initialAnswer || '',
        action_type: ACTION_CHAT_BOT,
        message_template: null,
      }],
      conversation_template: conversationTemplate,
      conversation_template_id: row.template?.id,
      src_address: row?.number?.number || null,
      src_address_id: row?.number?.number_id || null,
      type: row.type,
      address_book_id: (row.source_value as SourcePhoneBookValue)?.phone_book_id || null,
      phone_book_filter: phoneBookFilter,
      send_to_existing_contacts: row?.send_to_existing_contacts,
      ...draft,
      ...edit,
    },
  };

  if (!isResponder) {
    Object.assign(data, {
      ...omit(row.source_value?.data, ['data_lines', 'data_rowset', 'data_sheets', 'file']),
      ...omit(row.source_value, ['data']),
      ...{
        mapping: row.mapping,
        skip: row.mapping_param?.skipTopRows,
        phone_add: row.mapping_param?.addPrefix,
        phone_cut: row.mapping_param?.cutDigits,
        delimiter: row.mapping_param?.delimiter,
        enclosure: row.mapping_param?.quoteCharacter,
        sheets: row.mapping_param?.sheet,
        random: row.mapping_param?.random,
        randomEnabled: !!row.mapping_param?.random,

        msg_templates: [{
          text: row.questions?.initialMessage,
          enabled: true,
        }],
        attachment: row.route?.maxAttachmentSize ? row.questions?.initialAttachment?.id : null,
      },
    });
  } else {
    Object.assign(data, {
      source_type: SOURCE_RESPONDER,
      type: CAMPAIGN_TYPE_RESPONDER,
    });
  }

  schedulerOn && Object.assign(data, {
    schedule_times: row.schedule_times,
    schedule_enabled: true,
    timezone_based_schedule: true,
  });
  return data;
}
