import { USA } from 'shared-scope/const';
import { s } from 'i18n';
import { CustomError, Mapping, MappingTitle } from '../types';

export const DEFAULTS = {
  boost_mode: false,
  schedule_times: '10819,20819,30819,40819,50819',
  schedule_tz: 'PST',
  country_id: USA,
  remove_duplicated_numbers: true,
};

export const US_TIME_ZONES = [
  { k: 'HST', v: 'Hawaii Standard Time UTC-10' },
  { k: 'HDT', v: 'Hawaii Daylight Time UTC-9' },
  { k: 'AKST', v: 'Alaska Standard Time UTC-9' },
  { k: 'AKDT', v: 'Alaska Daylight Time UTC-8' },
  { k: 'PST', v: 'Pacific Standard Time UTC-8' },
  { k: 'PDT', v: 'Pacific Daylight Time UTC-7' },
  { k: 'MST', v: 'Mountain Standard Time UTC-7' },
  { k: 'MDT', v: 'Mountain Daylight Time UTC-6' },
  { k: 'CST', v: 'Central Standard Time UTC-6' },
  { k: 'CDT', v: 'Central Daylight Time UTC-5' },
  { k: 'EST', v: 'Eastern Standard Time UTC-5' },
  { k: 'EDT', v: 'Eastern Daylight Time UTC-4' },
  { k: 'AST', v: 'Atlantic Standard Time UTC-4' },
  { k: 'ADT', v: 'Atlantic Daylight Time UTC-3' },
  { k: 'ChST', v: 'Chamorro Standard Time UTC+10' },
];

export const GENDERS = [
  { k: 'F', v: s('Female') },
  { k: 'M', v: s('Male') },
];

export enum MappingColumns {
  COLUMN_ID = 'address_id',
  COLUMN_PHONE = 'phone_number',
  COLUMN_FIRST_NAME = 'first_name',
  COLUMN_LAST_NAME = 'last_name',
  COLUMN_GENDER = 'gender',
  COLUMN_STATE = 'state',
  COLUMN_COUNTRY = 'country',
  COLUMN_TIMEZONE = 'tz',
  COLUMN_CUSTOM_1 = 'custom_1',
  COLUMN_CUSTOM_2 = 'custom_2',
  COLUMN_CUSTOM_3 = 'custom_3',
  COLUMN_CUSTOM_4 = 'custom_4',
  COLUMN_CUSTOM_5 = 'custom_5',
  COLUMN_CUSTOM_6 = 'custom_6',
  COLUMN_CUSTOM_7 = 'custom_7',
  COLUMN_CUSTOM_8 = 'custom_8',
  COLUMN_CUSTOM_9 = 'custom_9',
  COLUMN_CUSTOM_10 = 'custom_10',
  COLUMN_CLIENT_MESSAGE_ID = 'client_message_id',
  COLUMN_TRACKING_ID = 'tracking_id',
}

export const OPT_IN_DATA = 'opt_in_data';

export const OPT_IN_DATA_FIELDS = [
  MappingColumns.COLUMN_TRACKING_ID,
];

const dontImport: MappingTitle = { do_not_import: s('Skip') };
const basedMappingColumns: MappingTitle = {
  [MappingColumns.COLUMN_PHONE]: s('Phone number'),
  [MappingColumns.COLUMN_FIRST_NAME]: s('First name'),
  [MappingColumns.COLUMN_LAST_NAME]: s('Last name'),
  [MappingColumns.COLUMN_GENDER]: s('Gender'),
  [MappingColumns.COLUMN_STATE]: s('State'),
  [MappingColumns.COLUMN_TIMEZONE]: s('Time zone'),
  [MappingColumns.COLUMN_TRACKING_ID]: s('Tracking ID'),
};
const customMappingColumns: MappingTitle = {
  [MappingColumns.COLUMN_CUSTOM_1]: 'Custom 1',
  [MappingColumns.COLUMN_CUSTOM_2]: 'Custom 2',
  [MappingColumns.COLUMN_CUSTOM_3]: 'Custom 3',
  [MappingColumns.COLUMN_CUSTOM_4]: 'Custom 4',
  [MappingColumns.COLUMN_CUSTOM_5]: 'Custom 5',
  [MappingColumns.COLUMN_CUSTOM_6]: 'Custom 6',
  [MappingColumns.COLUMN_CUSTOM_7]: 'Custom 7',
  [MappingColumns.COLUMN_CUSTOM_8]: 'Custom 8',
  [MappingColumns.COLUMN_CUSTOM_9]: 'Custom 9',
  [MappingColumns.COLUMN_CUSTOM_10]: 'Custom 10',
  [MappingColumns.COLUMN_CLIENT_MESSAGE_ID]: 'Client message ID',
};

export const ImportColumns: MappingTitle = {
  ...dontImport,
  ...basedMappingColumns,
};

export const MappingColumnsTitles: MappingTitle = {
  ...basedMappingColumns,
  ...customMappingColumns,
};

export const MAPPING_CAMPAIGN: Mapping = {
  [MappingColumns.COLUMN_ID]: 0,
  [MappingColumns.COLUMN_PHONE]: 1,
  [MappingColumns.COLUMN_COUNTRY]: 2,
  [MappingColumns.COLUMN_STATE]: 3,
  [MappingColumns.COLUMN_TIMEZONE]: 4,
  [MappingColumns.COLUMN_FIRST_NAME]: 5,
  [MappingColumns.COLUMN_LAST_NAME]: 6,
  [MappingColumns.COLUMN_GENDER]: 7,
  [MappingColumns.COLUMN_CUSTOM_1]: 8,
  [MappingColumns.COLUMN_CUSTOM_2]: 9,
  [MappingColumns.COLUMN_CUSTOM_3]: 10,
  [MappingColumns.COLUMN_CUSTOM_4]: 11,
  [MappingColumns.COLUMN_CUSTOM_5]: 12,
  [MappingColumns.COLUMN_CUSTOM_6]: 13,
  [MappingColumns.COLUMN_CUSTOM_7]: 14,
  [MappingColumns.COLUMN_CUSTOM_8]: 15,
  [MappingColumns.COLUMN_CUSTOM_9]: 16,
  [MappingColumns.COLUMN_CUSTOM_10]: 17,
  [MappingColumns.COLUMN_CLIENT_MESSAGE_ID]: 18,
};

export const MAPPING_PHONE_BOOK: Mapping = {
  [MappingColumns.COLUMN_ID]: 0,
  [MappingColumns.COLUMN_PHONE]: 1,
  [MappingColumns.COLUMN_STATE]: 2,
  [MappingColumns.COLUMN_TIMEZONE]: 3,
  [MappingColumns.COLUMN_FIRST_NAME]: 4,
  [MappingColumns.COLUMN_LAST_NAME]: 5,
  [MappingColumns.COLUMN_GENDER]: 6,
  [MappingColumns.COLUMN_CUSTOM_1]: 7,
  [MappingColumns.COLUMN_CUSTOM_2]: 8,
  [MappingColumns.COLUMN_CUSTOM_3]: 9,
  [MappingColumns.COLUMN_TRACKING_ID]: 10,
};

export const errorMapMessage: Record<CustomError, string> = {
  [CustomError.MESSAGE_IS_NOT_EMPTY]: s('Message can\'t be empty'),
  [CustomError.ANSWER_IS_NOT_EMPTY]: s('Answer can\'t be empty'),
  [CustomError.ATT_IS_NOT_ALLOWED]: s('Attachments are not allowed on your route'),
  [CustomError.ATT_BROKEN]: s('The file is broken and cannot be attached to the message'),
  [CustomError.ATT_MAX_SIZE]: s('The file is too big. It will be deleted when the campaign is created'),
};
