import React, { useState } from 'react';
import Loading from 'shared-scope/components/Loading';
import {
  Box,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { ToolsType, ToolType, ToolKey } from 'products/CampaignList/components/Tools';
import { useNavigate } from 'react-router-dom';


export default function Toolbox({
  campaignId,
  campaignName,
  tools,
  loading = false,
  loadingSize = 20,
  onAction,
  participants = 0,
}: ToolboxProps) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const actionHandler = (t: ToolType) => {
    const confirm = t.confirmation ? t.confirmation(campaignName) : null;
    t.handler && onAction && onAction(t.handler, confirm, t.key);

    if (t.redirect) {
      const path = (typeof t.redirect === 'string') ? t.redirect : t.redirect(campaignId);
      navigate(path);
    }
  };
  return (
    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
      {loading
        ? <Loading logo={false} size={loadingSize} />
        : (
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
            {(tools[0].key === ToolKey.Start || tools[0].key === ToolKey.Stop)
              ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    tools[0] && actionHandler(tools[0]);
                  }}
                  aria-label={tools[0].caption}
                  sx={{ marginLeft: '0px' }}
                >
                  {tools[0].icon}
                </IconButton>
              )
              : <Box sx={{ width: '20px' }} />}

            <Icon
              sx={{ transform: 'rotate(90deg)' }}
              className="icon-actions"
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setOpen(true);
              }}
            />
          </Stack>
        )}
      <Menu
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={anchorEl}
      >
        {tools
          .filter((t) => {
            if (t.key === ToolKey.ClonePollNewParticipants) {
              return participants > 0;
            }
            if (t.key === ToolKey.Start || t.key === ToolKey.Stop) {
              return false;
            }
            return true;
          })
          .map((t) => (
            <MenuItem
              key={t.key}
              onClick={() => {
                actionHandler(t);
                setOpen(false);
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {t.icon}
                <Typography>
                  {t.key === ToolKey.ClonePollNewParticipants ? `${t.caption} (${participants?.shortFormNumberStr()})` : t.caption}
                </Typography>
              </Stack>
            </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}


export interface ToolboxProps {
  campaignId: number
  campaignName: string
  tools: ToolsType
  loadingSize?: number
  loading?: boolean
  onAction?: (action: Function, confirm: string | null, key: string) => any
  className?: string
  buttonStyle?: string
  short?: boolean
  toolsStyle?: string
  participants?: number
}
